import { useState } from 'react'
import NextImage from 'next/image'
import {
  Column,
  HasMoreProductsBox,
  Icon,
  Picture,
  ProductsImagesCarousel,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  formatURLfromVtex,
  isValidArrayWithData,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'shared/hooks'
import { CarouselProductsLoader } from './CarouselProductsLoader'
import styles from './styles.module.css'

export const CarouselProducts = () => {
  const [isShowCarousel, setIsShowCarousel] = useState(false)
  const { checkoutConfig } = useCheckoutConfig()
  const { data } = useCheckoutV2()

  const totalItems = data?.totalItems
  const items = data?.items
  const itemImages =
    isValidArrayWithData(items) &&
    items.map((item) => {
      return item.image
    })

  const handleToggleCarousel = () => {
    setIsShowCarousel(!isShowCarousel)
  }

  if (checkoutConfig.isLoading) {
    return <CarouselProductsLoader />
  }

  return (
    <>
      <BigScreen>
        <Column
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          customHeight='200px'
          gap='16px'
          id={getBemId('carousel-products', 'container')}
          minWidth='700px'
          padding='16px'
        >
          <Row
            alignItems='center'
            gap={4}
          >
            <Text
              fontSize='xl'
              fontWeight='medium'
              id={getBemId('carouselProducts', 'title', 'desktop')}
            >
              Mis productos
            </Text>
            <Text
              customColor={getGlobalStyle('--color-neutral-gray-dark')}
              fontSize='xl'
              fontWeight='regular'
              id={getBemId('carouselProducts', 'amount', 'desktop')}
            >
              ({totalItems})
            </Text>
          </Row>
          <Row
            alignItems='center'
            gap={16}
          >
            {isValidArrayWithData(items) &&
              items.slice(0, 5).map((item, index) => {
                return (
                  <Picture
                    alt={`${item?.image}_${index}`}
                    height='100px'
                    id={getBemId('carouselProducts', 'picture', item?.image)}
                    idPictureContainer={getBemId(
                      'carouselProducts',
                      'picture',
                      'container'
                    )}
                    key={`${item?.image}-${index}`}
                    nextImage={NextImage}
                    objectFit='cover'
                    src={item?.image}
                    width='100px'
                  />
                )
              })}
            {isValidArrayWithData(items) && items.length > 5 && (
              <HasMoreProductsBox
                id={getBemId(
                  'carouselProducts',
                  'hasMoreProductsBox',
                  'desktop'
                )}
                quantity={totalItems}
              />
            )}
          </Row>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column
          customClassName={styles.container}
          id={getBemId('carousel-products', 'container')}
          minHeight='auto'
          padding={!isShowCarousel ? '0 0 24px 0' : '0'}
        >
          <Row
            alignItems='center'
            justifyContent='between'
          >
            <Row
              alignItems='center'
              gap={4}
              onClick={handleToggleCarousel}
            >
              <Text
                fontSize='lg'
                fontWeight='medium'
                id={getBemId('carouselProducts', 'title', 'mobile')}
              >
                Mis productos
              </Text>
              <Text
                fontSize='md'
                id={getBemId('carouselProducts', 'amount', 'mobile')}
              >
                ({totalItems})
              </Text>
            </Row>
            <Row
              customWidth='max-content'
              onClick={handleToggleCarousel}
            >
              <Icon
                color={getGlobalStyle('--color-base-black')}
                customSize={16}
                name='ArrowRightNavigate2'
                transform={isShowCarousel ? 'rotate(270deg)' : 'rotate(90deg)'}
              />
            </Row>
          </Row>
          {isShowCarousel && (
            <>
              <Spacer.Horizontal customSize={24} />
              <ProductsImagesCarousel
                formatURLfromVtex={formatURLfromVtex}
                images={itemImages}
                nextImage={NextImage}
              />
              <Spacer.Horizontal customSize={24} />
            </>
          )}
        </Column>
      </SmallScreen>
    </>
  )
}
