export const TITLE_ADD_NEW = 'Agregar nueva Giftcard'
export const TITLE_SHOW_GIFTCARDS = 'Mis Giftcards'
export const MAX_LENGTH_CARD_NUMBER = 22
export const MAX_LENGTH_PIN = 6
export const MIN_LENGTH_PIN = 4
export const INITIAL_VALUES = {
  balance: '',
  type: '',
  cardNumber: '',
  pin: '',
  currentIndex: 0
}
