import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { PaymentMethodLoader } from '../PaymentMethods/PaymentMethodsLoader'
import { DiscountsBackbone } from '../Discounts/DiscountsBackbone'
import { SaveBagsBackbone } from '../SaveBags/SaveBagsBackbone'
import { CarouselProductsLoader } from '../CarouselProducts/CarouselProductsLoader'
import { SummaryBackbone } from '../Summary/SummaryBackbone'
import { PayBtnLoader } from '../PayBtn/PayBtnLoader'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export const CheckoutMainLoader = () => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  return (
    <>
      <Container
        isWrap={isMobile}
        padding={isMobile ? '0 16px' : '0'}
      >
        <Column
          gap={24}
          margin={isMobile ? '0' : '0 0 200px 0'}
          maxWidth='700px'
        >
          <PaymentMethodLoader />
          <DiscountsBackbone />
          <SaveBagsBackbone />
          <CarouselProductsLoader />
        </Column>
        <Spacer.Vertical customSize={44} />
        <Column
          gap={20}
          margin={isMobile ? '0 0 40px 0' : '0 0 100px 0'}
          maxWidth='375px'
          position='sticky'
        >
          <BigScreen>
            <PayBtnLoader />
          </BigScreen>
          <SummaryBackbone />
          <BigScreen>
            <Row justifyContent='center'>
              <Row maxWidth='109px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={14}
                />
              </Row>
            </Row>
          </BigScreen>
        </Column>
      </Container>
      <SmallScreen>
        <Column
          backgroundColor={getGlobalStyle('--color-base-white')}
          borderRadius={getGlobalStyle('--border-radius-md')}
          bottom='0'
          boxShadow={getGlobalStyle('--box-shadow-xs')}
          padding='20px 16px 16px 16px'
          position='sticky'
          zIndex='20'
        >
          <PayBtnLoader />
        </Column>
      </SmallScreen>
    </>
  )
}
