import {
  Backbone,
  Column,
  Row,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export const SaveBagsBackbone = () => {
  return (
    <>
      <BigScreen>
        <Container
          customHeight='max-content'
          gap={16}
          maxHeight='700px'
        >
          <Column
            alignItems='center'
            gap={20}
            padding='16px'
          >
            <Row gap={20}>
              <Row maxWidth='250px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={15}
                />
              </Row>
            </Row>
            {Array.from({ length: 2 }).map((_, index) => {
              return (
                <Column key={index}>
                  <Row
                    alignItems='center'
                    gap={12}
                  >
                    <Row maxWidth='30px'>
                      <Backbone
                        borderRadius={getGlobalStyle('--border-radius-full')}
                        height={30}
                      />
                    </Row>
                    <Column gap={8}>
                      <Row maxWidth='200px'>
                        <Backbone
                          borderRadius={getGlobalStyle('--border-radius-lg')}
                          height={14}
                        />
                      </Row>
                      <Row maxWidth='300px'>
                        <Backbone
                          borderRadius={getGlobalStyle('--border-radius-lg')}
                          height={14}
                        />
                      </Row>
                    </Column>
                  </Row>
                </Column>
              )
            })}
          </Column>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          customHeight='max-content'
          gap={16}
          maxHeight='700px'
        >
          <Column
            alignItems='center'
            gap={20}
            padding='16px'
          >
            <Row gap={20}>
              <Row maxWidth='250px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={15}
                />
              </Row>
            </Row>
            {Array.from({ length: 2 }).map((_, index) => {
              return (
                <Column key={index}>
                  <Row
                    alignItems='center'
                    gap={12}
                  >
                    <Column gap={8}>
                      <Row maxWidth='100px'>
                        <Backbone
                          borderRadius={getGlobalStyle('--border-radius-lg')}
                          height={14}
                        />
                      </Row>
                      <Row maxWidth='150px'>
                        <Backbone
                          borderRadius={getGlobalStyle('--border-radius-lg')}
                          height={14}
                        />
                      </Row>
                    </Column>
                    <Row maxWidth='30px'>
                      <Backbone
                        borderRadius={getGlobalStyle('--border-radius-full')}
                        height={30}
                      />
                    </Row>
                  </Row>
                </Column>
              )
            })}
          </Column>
        </Container>
      </SmallScreen>
    </>
  )
}
