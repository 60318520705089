import { CurtainDown } from '@smu-chile/pkg-unimarc-components'
import {
  goToHome,
  orderEntriesByIdCF,
  useCurtainStatus
} from '@smu-chile/pkg-unimarc-hooks'
import { useCurtainAssets } from '@smu-chile/pkg-unimarc-hooks/hooks/useCurtainAssets'

interface CurtainWrapperProps {
  children: React.ReactNode
}

const CurtainWrapper = ({ children }: CurtainWrapperProps) => {
  const { assets } = useCurtainAssets() || {}
  const { curtainStatus } = useCurtainStatus() || {}

  const callGoToHome = () => {
    window.location.href = goToHome()
  }
  const containerTextCurtain = orderEntriesByIdCF(assets?.container)

  if (curtainStatus?.items[0]?.fields?.cortina) {
    return (
      <CurtainDown
        assets={assets}
        callGoToHome={callGoToHome}
        containerTextCurtain={containerTextCurtain}
      />
    )
  }

  return <>{children}</>
}

export default CurtainWrapper
