import {
  Column,
  Row,
  Spacer,
  Text,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ModalBodyProps {
  isMobile?: boolean
  codeError: string
}

export const ModalBody = ({
  isMobile,
  codeError
}: ModalBodyProps): React.ReactElement => {
  return (
    <Column
      id={getBemId('modalErrorDeliveryWindow', 'content')}
      minHeight={isMobile ? 'none' : '100%'}
    >
      <Spacer.Horizontal customSize={20} />
      <Row
        alignItems='center'
        id={getBemId('modalErrorDeliveryWindow', 'icon')}
        justifyContent='center'
      >
        <Picture
          alt={codeError}
          height={isMobile ? '200px' : '140px'}
          objectFit='cover'
          src='/images/error/kiwi-slices.png'
          width={isMobile ? '235px' : '180px'}
        />
      </Row>
      <Spacer.Horizontal customSize={isMobile ? 27 : 18} />
      <Row alignItems='center'>
        <Column
          alignItems='center'
          tagName='article'
        >
          <Text
            fontSize='xl'
            fontWeight='semibold'
            id={getBemId('modalErrorDeliveryWindow', 'title')}
            lineHeight='normal'
            textAlign='center'
          >
            Tu horario de entrega expiró
          </Text>
          <Spacer.Horizontal size={isMobile ? 16 : 12} />
          <Text
            fontSize='lg'
            fontWeight='regular'
            id={getBemId('modalErrorDeliveryWindow', 'description')}
            lineHeight='normal'
            textAlign='center'
            width={isMobile ? '343px' : 'auto'}
          >
            Revisa tu nuevo horario disponible para continuar con el pago.
          </Text>
          <Spacer.Horizontal size={8} />
          <Column
            alignItems='center'
            customHeight='29px'
            justifyContent='center'
            maxWidth='174px'
          >
            <Text
              customColor={getGlobalStyle('--color-primary-disabled')}
              fontSize='md'
              fontWeight='medium'
            >
              {codeError}
            </Text>
          </Column>
        </Column>
      </Row>
    </Column>
  )
}
