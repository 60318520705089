import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  Cookies,
  getMiniFooterContent,
  isValidArrayWithData,
  useCards,
  useCheckoutV2,
  useContentful,
  useOrders,
  useQuery,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { IMiniFooter } from '@smu-chile/pkg-unimarc-components/shared/interfaces/IMiniFooter'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { validatePaymentButton, parseContentfulData } from './helpers'
import {
  CODE_KLAP_CREDIT,
  CODE_KLAP_DEBIT,
  CODE_ONECLICK_CREDIT,
  CODE_ONECLICK_DEBIT,
  CONTENTFUL_IDS,
  CONTENTFUL_TYPES,
  DEFAULT_NUMBER_OF_INSTALLMENTS
} from 'shared/constants'

interface ImageProps {
  id: string
  type: string
  createdAt: string
  updatedAt: string
  locale: string
  fields: {
    title: string
    description: string
    file: {
      url: string
      details: {
        size: number
        image: {
          width: number
          height: number
        }
      }
      fileName: string
      contentType: string
    }
  }
}

interface ICheckoutConfig {
  termsAndConditions: boolean
  loadingTransaction: boolean
  isLoading: boolean
  isEnablePaymentButton: boolean
  orderformId: string
  saleschannel: string
  caToken: string
  idToken: string
  installments: { id: string; text: string }[]
  stores?: string[]
  showSaveBags?: boolean
  banner?: {
    contentType: string
    fields: {
      label: string
      imageDesktop: ImageProps
      imageMobile: ImageProps
      webTablet: ImageProps
      referenceType: string
    }
  }
  footer?: IMiniFooter[]
}

/**
 * Default configuration for the checkout.
 */
const defaultConfig: ICheckoutConfig = {
  termsAndConditions: false,
  loadingTransaction: false,
  isLoading: false,
  isEnablePaymentButton: false,
  orderformId: '',
  saleschannel: '',
  idToken: '',
  caToken: '',
  installments: DEFAULT_NUMBER_OF_INSTALLMENTS,
  stores: [],
  showSaveBags: false,
  banner: null,
  footer: []
}
const keyToQuery = 'checkoutConfig'

/**
 * Custom hook for managing the checkout configuration.
 * @returns An object containing the checkout configuration and a function to mutate it.
 * @example
 * const { checkoutConfig, mutateCheckoutConfig } = useCheckoutConfig()
 * mutateCheckoutConfig({ termsAndConditions: true })
 * console.log(checkoutConfig.termsAndConditions) // true
 * console.log(checkoutConfig.loadingTransaction) // false
 * console.log(checkoutConfig.isLoading) // false
 */
export const useCheckoutConfig = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { isLoading: isLoadingCheckout, data: checkout } = useCheckoutV2()
  const { isLoading: isLoadingCards, data: dataCards } = useCards({
    version: 2
  })
  const { isLoading: isLoadingOrders, data: orders } = useOrders({
    reactQuery: {
      enabled: true,
      refetchOnWindowFocus: false,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })
  const { data: contentfulData } = useContentful({
    options: {
      'sys.id': CONTENTFUL_IDS.CHECKOUT_CONFIG
    },
    reactQuery: {
      queryKey: 'checkout_config'
    }
  })

  const configData = useContentful({
    options: {
      content_type: CONTENTFUL_TYPES.GENERAL_CONFIG
    },
    reactQuery: {
      queryKey: 'general_config'
    }
  })
  const { data: footerDataCf, isLoading: isLoadingFooter } = useContentful({
    id_contentful: 'contentful_minifooter',
    options: {
      content_type: 'unimarcWebTopFooter',
      'fields.label[in]': 'Mini Footer (Desktop)',
      'fields.platform': 'Web Unimarc eComm'
    }
  })

  const isLoading: boolean = isLoadingCheckout || isLoadingCards
  const getCookies: string = Cookies.get(keyToQuery) || '{}'
  const parsedCookies: ICheckoutConfig = JSON.parse(getCookies)
  const initialData: ICheckoutConfig = {
    ...defaultConfig,
    orderformId: parsedCookies.orderformId || defaultConfig.orderformId,
    stores: parsedCookies.stores || defaultConfig.stores,
    footer: parsedCookies.footer || defaultConfig.footer,
    termsAndConditions:
      parsedCookies.termsAndConditions || defaultConfig.termsAndConditions
  }

  // Fetches the checkout configuration from the server
  const { data } = useQuery(
    keyToQuery,
    () => {
      return initialData
    },
    {
      enabled: false,
      initialData
    }
  )

  /**
   * Mutates the checkout configuration.
   * @param props - The properties to update in the configuration.
   */
  const mutate = (props: Partial<ICheckoutConfig>): void => {
    const prevData: ICheckoutConfig =
      queryClient.getQueryData(keyToQuery) || initialData
    const newData = {
      ...initialData,
      ...prevData,
      ...data,
      isLoading,
      ...props
    }

    Cookies.set(keyToQuery, JSON.stringify(newData), {
      expires: new Date(new Date().getTime() + 5 * 60 * 1000) // 5 minutes
    })
    queryClient.setQueryData(keyToQuery, newData)
  }

  /**
   * Gets the total amount of the checkout and giftcards
   * @returns The total amount of the checkout.
   */
  const getTotalAmountByCards = () => {
    let totalAmount = checkout?.value

    if (isValidArrayWithData(checkout?.paymentInfo?.availablePaymentMethods)) {
      const getPaymentMethods = checkout?.paymentInfo?.availablePaymentMethods
      const paymentMethodsWithoutDiscount = [
        CODE_ONECLICK_DEBIT,
        CODE_ONECLICK_CREDIT,
        CODE_KLAP_DEBIT,
        CODE_KLAP_CREDIT
      ]
      const getFirstPaymentWitoutDiscount = getPaymentMethods?.find(
        (payment) => {
          return paymentMethodsWithoutDiscount.includes(payment.id)
        }
      )
      totalAmount = getFirstPaymentWitoutDiscount?.price ?? checkout?.value
    }

    return totalAmount
  }

  // Updates the checkout configuration when the loading status changes
  useEffect(() => {
    mutate({ isLoading })
  }, [isLoadingCheckout, isLoadingCards])

  // Updates the validation of the payment button
  useEffect(() => {
    const handleValidationGeneralData = async () => {
      let installments = DEFAULT_NUMBER_OF_INSTALLMENTS
      let stores = []
      let showSaveBags = false
      let banner = null

      if (contentfulData) {
        const newContentFulData = await parseContentfulData(contentfulData, {
          queryClient
        })

        // find the installments in the contentful
        const getItemOfInstallments =
          newContentFulData?.items?.[0]?.fields?.items?.find((item) => {
            return item?.fields?.cuotas
          })
        const getInstallments = getItemOfInstallments?.fields
          ?.cuotas as string[]
        installments = getInstallments?.map((installment) => {
          const text =
            parseInt(installment) === 1 ? 'Sin cuotas' : `${installment} cuotas`
          return {
            id: installment,
            text
          }
        })

        // find the banner in the contentful
        const getItemOfBanner =
          newContentFulData?.items?.[0]?.fields?.items?.find((item) => {
            return item?.contentType === 'imageBannerWeb'
          })
        banner = getItemOfBanner || null

        // find the save bags in the contentful
        if (configData?.data) {
          const configDataParsed = await parseContentfulData(configData.data, {
            queryClient
          })
          const configShowSaveBags =
            configDataParsed?.items?.[0]?.fields?.mostrarBolsasCheckout ?? false
          stores =
            newContentFulData?.items?.[0]?.fields?.items
              ?.find((item) => {
                return item?.fields?.blockId === 'Tiendas'
              })
              ?.fields?.items?.map((item) => {
                return item?.fields
              }) || []
          const listCourierids = stores?.map((store) => {
            return store?.courierid
          })
          const currentCourierId = checkout?.shipping?.address?.courierId
          const shippingAddressIntoTheStores =
            listCourierids?.includes(currentCourierId)
          showSaveBags = configShowSaveBags && shippingAddressIntoTheStores
        }
      }

      mutate({ installments, stores, showSaveBags, banner })
    }

    handleValidationGeneralData()
  }, [checkout, dataCards?.cards, contentfulData])

  useEffect(() => {
    Cookies.set(keyToQuery, JSON.stringify(data), {
      expires: new Date(new Date().getTime() + 5 * 60 * 1000) // 5 minutes
    })

    const isEnablePaymentButton = validatePaymentButton({
      checkout,
      cards: dataCards?.cards,
      termsAndConditions: data.termsAndConditions
    })
    mutate({ isEnablePaymentButton })
  }, [data, checkout, dataCards?.cards])

  // If the user has orders, it will set the terms and conditions as true
  useEffect(() => {
    if (!data.termsAndConditions) {
      mutate({
        termsAndConditions:
          isValidArrayWithData(orders?.data?.orders) ||
          isValidArrayWithData(dataCards?.cards)
      })
    }
  }, [orders, isLoadingOrders, dataCards?.cards])

  useEffect(() => {
    if (checkout?.status === 401) Cookies.remove(keyToQuery)
  }, [checkout, dataCards?.cards])

  useEffect(() => {
    if (isLoadingFooter) return
    const footerData: IMiniFooter[] = getMiniFooterContent(
      footerDataCf,
      router
    ) as IMiniFooter[]
    if (isValidArrayWithData(footerData)) mutate({ footer: footerData })
  }, [footerDataCf, isLoadingFooter])

  return {
    checkoutConfig: data,
    mutateCheckoutConfig: mutate,
    getTotalAmountByCards
  }
}
