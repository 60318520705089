import { ReactNode } from 'react'
import { SwiperCarousel } from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export interface ICarouselGiftCardsProps {
  children: ReactNode[]
  initialSlide?: number
  handleChooseGiftCard?: (index: number) => void
}

export const CarouselGiftCards = ({
  children,
  initialSlide = 0,
  handleChooseGiftCard
}: ICarouselGiftCardsProps) => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  return (
    <SwiperCarousel
      breakpoints={{
        320: {
          slidesPerView: 1.4
        },
        420: {
          slidesPerView: 1.5
        },
        640: {
          slidesPerView: 1.6
        }
      }}
      centerInsufficientSlides={true}
      centeredSlides={true}
      initialSlide={initialSlide}
      items={children}
      onSlideChange={(slide) => {
        if (handleChooseGiftCard) {
          handleChooseGiftCard(slide?.activeIndex)
        }
      }}
      pagination={{ clickable: true, enabled: !isMobile }}
      slideToClickedSlide={true}
      slidesPerView={1.6}
      spaceBetween={16}
    />
  )
}
