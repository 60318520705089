import {
  Backbone,
  Column,
  Row,
  Container
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export const DiscountsBackbone = () => {
  return (
    <>
      <BigScreen>
        <Container
          customHeight='max-content'
          gap={16}
          maxHeight='691px'
        >
          <Column
            alignItems='center'
            gap={20}
            padding='16px'
          >
            <Row gap={20}>
              <Row maxWidth='198px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={15}
                />
              </Row>
            </Row>
            <Row
              alignItems='center'
              gap={12}
            >
              <Row maxWidth='39px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={33}
                />
              </Row>
              <Row justifyContent='between'>
                <Row maxWidth='168px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-lg')}
                    height={14}
                  />
                </Row>

                <Row maxWidth='63px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-lg')}
                    height={14}
                  />
                </Row>
              </Row>
            </Row>
          </Column>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          customHeight='max-content'
          gap={16}
          maxHeight='691px'
        >
          <Column
            alignItems='center'
            gap={20}
            padding='16px'
          >
            <Row gap={20}>
              <Row maxWidth='220px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  height={14}
                />
              </Row>
            </Row>
            <Row
              alignItems='center'
              gap={12}
            >
              <Row maxWidth='23px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={19}
                />
              </Row>
              <Row justifyContent='between'>
                <Row maxWidth='136px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-lg')}
                    height={12}
                  />
                </Row>

                <Row maxWidth='97px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-lg')}
                    height={12}
                  />
                </Row>
              </Row>
            </Row>
          </Column>
        </Container>
      </SmallScreen>
    </>
  )
}
