import {
  Column,
  Row,
  Icon,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ModalBodyProps {
  isMobile?: boolean
}

export const ModalBody = ({ isMobile }: ModalBodyProps): React.ReactElement => {
  return (
    <Column
      id={getBemId('modalAlertMembership', 'content')}
      minHeight={isMobile ? 'none' : '100%'}
    >
      <Row
        id={getBemId('modalAlertMembership', 'icon')}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          transform: 'rotate(4deg)'
        }}
      >
        <Icon
          color={getGlobalStyle('--color-primary-membership')}
          customSize={isMobile ? 163 : 120}
          name='DiamondMembership'
        />
      </Row>
      <Spacer.Horizontal customSize={isMobile ? 27 : 18} />
      <Row alignItems='center'>
        <Column
          alignItems='center'
          tagName='article'
        >
          <Text
            fontSize='xl'
            fontWeight='semibold'
            id={getBemId('modalAlertMembership', 'title')}
            lineHeight='normal'
            textAlign='center'
          >
            Tarjeta asociada a tu membresía
          </Text>
          <Spacer.Horizontal size={isMobile ? 16 : 12} />
          <Text
            fontSize='lg'
            fontWeight='regular'
            id={getBemId('modalAlertMembership', 'description')}
            lineHeight='normal'
            textAlign='center'
          >
            Podrás eliminar esta tarjeta cuando hayas actualizado el medio de
            pago de tu membesía.
          </Text>
        </Column>
      </Row>
    </Column>
  )
}
