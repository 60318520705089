import { createContext, useContext, useState, ReactNode, useMemo } from 'react'

interface LayoutContextType {
  showHeaderAndFooter: boolean
  setShowHeaderAndFooter: (show: boolean) => void
}

const LayoutContext = createContext<LayoutContextType>({
  showHeaderAndFooter: true,
  setShowHeaderAndFooter: () => {
    return true
  }
})

type LayoutProviderProps = {
  children: ReactNode
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({
  children
}: LayoutProviderProps) => {
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(true)

  const contextValue = useMemo(() => {
    return { showHeaderAndFooter, setShowHeaderAndFooter }
  }, [showHeaderAndFooter, setShowHeaderAndFooter])

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  )
}

export function useLayoutContext() {
  return useContext(LayoutContext)
}
