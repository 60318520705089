import { useEffect } from 'react'
import {
  Column,
  Container,
  DropdownLabel,
  InputLabel,
  InstallmentSimulator,
  InstallmentSimulatorProps,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { PointLoader } from '@smu-chile/pkg-unimarc-components/stories/atoms/PointLoader'
import { useTooltip } from '@smu-chile/pkg-unimarc-components/stories/molecules/InstallmentSimulator/helpers/hooks/useTooltip'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'shared/hooks'
import { IUseModalUnipayValidations } from '../hooks/useModalUnipayValidations'

export type IModalUnipayContentProps = Partial<IUseModalUnipayValidations>

export const ModalUnipayContent = ({
  values,
  isValidatingRut,
  isDisabledInstallments,
  simulatePaymentData,
  isSimulationLoading,
  rutStatus,
  errors,
  handleChange,
  handleCleanInput
}: IModalUnipayContentProps) => {
  const { checkoutConfig } = useCheckoutConfig()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const { openTooltip, handleOnClick, setOpenTooltip } = useTooltip()

  const installmentsProps: InstallmentSimulatorProps = {
    increase: simulatePaymentData?.increase,
    cae: simulatePaymentData?.cae,
    total: simulatePaymentData?.total,
    quotas: simulatePaymentData?.quotas,
    quotaValue: simulatePaymentData?.quotaValue,
    openTooltip,
    isSimulationLoading,
    handleOnClick,
    setOpenTooltip
  }

  const handleChangeSelect = (data: string) => {
    handleChange({
      target: {
        name: 'installments',
        value: data
      } as unknown as EventTarget
    } as unknown as React.ChangeEvent<HTMLInputElement>)
  }

  useEffect(() => {
    // move the scroll to the bottom of the modal when the simulation is loaded
    if (typeof window === 'undefined') return

    let element
    if (isMobile) {
      element = document?.getElementById(
        getBemId(`ModalUnipayMobile-mobile-modal-wrapper`, 'body')
      )
    } else {
      element = document?.getElementById(
        getBemId(`ModalUnipayDesktop-desktop-modal-wrapper`, 'body')
      )
    }

    element?.scrollTo(0, 1000)
    element?.scroll({
      top: 1000,
      behavior: 'smooth'
    })
  }, [isSimulationLoading, installmentsProps?.quotas])

  return (
    <Column
      alignItems='center'
      customHeight={isMobile ? 'calc(100% - 45px)' : '100%'}
      justifyContent='between'
      position='relative'
    >
      <div
        id='portal-unipay'
        style={{
          position: 'absolute',
          top: '8px',
          width: '343px',
          zIndex: getGlobalStyle('--z-index-10')
        }}
      />
      <Column
        gap={46}
        id={getBemId('container', 'content')}
        padding='24px 24px 0px 24px'
      >
        <Row justifyContent='center'>
          <Picture
            alt='logo_unipay'
            height='161px'
            id={getBemId('modalUnipayContent', 'cardImage', 'unipay')}
            src='/images/unipay_modal_minified.png'
            width='268px'
          />
        </Row>
        <Column id={getBemId('container', 'form')}>
          <Column gap={6}>
            <InputLabel
              appendSlot={
                isValidatingRut ? (
                  <Container id={getBemId('modalUnipayContent', 'loader')}>
                    <PointLoader theme='white' />
                  </Container>
                ) : null
              }
              appendSlotContainerProps={{
                width: 55
              }}
              disabled={isValidatingRut}
              error={rutStatus === 'error'}
              fontSize='base'
              fullWidth
              height={44}
              id={getBemId('modalUnipayContent', 'inputLabel')}
              inputWidth='360px'
              label='Ingresa tu RUT'
              name='rut'
              onChange={handleChange}
              onClickIcon={() => {
                handleCleanInput('rut')
              }}
              showXIcon={!isValidatingRut && values?.rut?.length > 0}
              success={rutStatus === 'success'}
              value={values.rut}
              variant='compact'
            />
            {Boolean(errors?.rut) && (
              <Row padding='0 16px'>
                <Text
                  customColor={getGlobalStyle('--color-feedback-error')}
                  fontSize='sm'
                  id={getBemId('modalUnipayContent', 'errorRut')}
                >
                  {errors?.rut}
                </Text>
              </Row>
            )}
          </Column>
          <Spacer.Horizontal customSize={32} />
          <DropdownLabel
            disabled={isValidatingRut || isDisabledInstallments}
            id={getBemId('modalUnipayContent', 'dropdownLabel')}
            label='N° de cuotas'
            maxItemsToDisplay={6}
            onChange={handleChangeSelect}
            options={checkoutConfig?.installments}
            positionDropdownList='fixed'
            value={values.installments}
            variant='outlined'
          />
          {(isSimulationLoading || installmentsProps?.quotas) && (
            <>
              <Spacer.Horizontal customSize={16} />
              <InstallmentSimulator {...installmentsProps} />
              <Spacer.Horizontal customSize={30} />
            </>
          )}
        </Column>
      </Column>
    </Column>
  )
}
