import Link from 'next/link'
import {
  HeaderCart as HeaderCheckout,
  HeaderSimpleMobile,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import { LAYOUT_WIDTH } from 'shared/constants'

export const LayoutHeader = () => {
  const cartUrl = process.env.NEXT_PUBLIC_CARTURL
  const homeUrl = process.env.NEXT_PUBLIC_HOMEURL

  const handleGoCart = () => {
    if (cartUrl) window.location.href = `${cartUrl}/MyShipments`
  }

  return (
    <>
      <BigScreen>
        <>
          <HeaderCheckout
            isMobile={false}
            linkWrapper={Link}
            logo='LogoHeaderUnimarcCL'
            logoId={getBemId('headerLogo', 'image')}
            logoUrl={homeUrl}
            onClickBack={handleGoCart}
            title='Mi pago'
            width={LAYOUT_WIDTH}
          />
          <Spacer.Horizontal size={32} />
        </>
      </BigScreen>

      <SmallScreen>
        <>
          <HeaderSimpleMobile
            goBackLogic={handleGoCart}
            isSticky={true}
            title='Mi pago'
            titleProps={{
              customClassName: '',
              fontWeight: 'medium'
            }}
          />
          <Spacer.Horizontal customSize={78} />
        </>
      </SmallScreen>
    </>
  )
}
