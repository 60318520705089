import { useState } from 'react'
import {
  Column,
  Icon,
  NotificationBubble,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  getUnipayGenerateSecurityCode,
  isValidArrayWithData,
  normalizeString,
  trigger,
  useCards,
  useCheckoutV2,
  useContentful,
  useEvents
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'shared/hooks'
import { ModalListNewPayments } from './ModalListNewPayments'
import { ModalListPaymentsCards } from './ModalListPaymentsCards'
import { CardSelected } from './CardSelected'
import { PaymentMethodLoader } from './PaymentMethodsLoader'
import { ModalDeleteCard } from './ModalDeleteCard'
import { GifcardComponent, IGiftCard } from './GifcardComponent'
import { ModalDeleteGiftCard } from './ModalDeleteGiftCard'
import { ModalConfirmTermsAndConditions } from '../ModalConfirmTermsAndConditions'
import { ModalUnipay } from './ModalUnipay'
import { ModalOTPUnipayWrapper } from '../ModalOTPUnipayWrapper'
import { Banner } from './Banner'
import {
  PAYMENT_METHODS,
  CONTENTFUL_IMG_IDS,
  TOAST_MESSAGES_MODAL_UNIPAY_OTP
} from 'shared/constants'
import { IPostSimulationPaymentUnipayResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostSimulationPaymentUnipay'
import { ModalAlertMembership } from '../ModalAlertMembership'
import styles from './styles.module.css'

export interface IOtpData {
  isLoading: boolean
  message: string
  rut: string
  phone: string
  token: string
  simulatePaymentData?:
    | IPostSimulationPaymentUnipayResponse
    | Record<string, never>
}

export interface PaymentMethosProps {
  showNotificationBubble: boolean
}

export const PaymentMethods = ({
  showNotificationBubble
}: PaymentMethosProps) => {
  // UI
  const [isSelectedPaymentMethod, setIsSelectedPaymentMethod] = useState(false)
  const [isSelectedCard, setIsSelectedCard] = useState(false)
  const [isDeleteCardModalRequested, setIsDeleteCardModalRequested] =
    useState(false)
  const [isOpenUnipayModal, setIsOpenUnipayModal] = useState(false)
  const [currentCardId, setCurrentCardId] = useState('')
  const [openDeleteGCModal, setOpenDeleteGCModal] = useState(false)
  const [giftCardToDelete, setGiftCardToDelete] = useState<IGiftCard>({})
  const [showConfirmTermsAndConditions, setShowConfirmTermsAndConditions] =
    useState(false)
  const [openModalAlertMembership, setOpenModalAlertMembership] =
    useState(false)
  const [isOpenOTP, setIsOpenOTP] = useState(false)
  const [otpData, setOtpData] = useState<IOtpData>({
    isLoading: false,
    message: '',
    rut: '',
    phone: '',
    token: ''
  })
  // ARRANGE
  const { data: checkout } = useCheckoutV2()
  const { data: dataCards } = useCards({
    reactQuery: {
      retry: 3
    },
    version: 2
  })
  const { checkoutConfig, mutateCheckoutConfig } = useCheckoutConfig()
  const deleteGCModalImg = useContentful({
    id_contentful: 'contentful-assets-remove-gift-cart-modal',
    options: {
      'sys.id': CONTENTFUL_IMG_IDS.REMOVE_GC_MODAL_IMG
    },
    type: 'assets'
  })

  const deleteGCModalImage =
    deleteGCModalImg?.data?.['items']?.[0]?.fields?.file?.url ?? ''
  const filterCards =
    isValidArrayWithData(dataCards?.cards) &&
    dataCards?.cards?.filter((card) => {
      return (
        normalizeString(card?.cardType) !==
        normalizeString(PAYMENT_METHODS.GIFTCARD)
      )
    })
  const hasSelectedCard =
    isValidArrayWithData(checkout?.paymentInfo?.selectedPayments) &&
    isValidArrayWithData(dataCards?.cards)

  const handleOpenModal = (checkGlobalConfig = true) => {
    if (checkGlobalConfig && !checkoutConfig.termsAndConditions) {
      setShowConfirmTermsAndConditions(true)
      return
    }

    if (!isValidArrayWithData(filterCards)) {
      setIsSelectedPaymentMethod(true)
    } else {
      setIsSelectedCard(true)
    }
  }

  const handleToggleSelectPaymentMethod = () => {
    setIsSelectedPaymentMethod(!isSelectedPaymentMethod)
  }

  const handleToggleListPaymentCardsModal = (checkGlobalConfig = true) => {
    if (checkGlobalConfig && !checkoutConfig.termsAndConditions) {
      setShowConfirmTermsAndConditions(true)
      return
    }

    setIsSelectedCard(!isSelectedCard)
  }

  const handleToggleDeleteCardModal = async (
    cardId?: string,
    isBackToListPaymentCards = true
  ) => {
    if (isBackToListPaymentCards) handleToggleListPaymentCardsModal()

    if (typeof cardId === 'string' && cardId.length > 0) {
      setCurrentCardId(cardId)
    }

    setIsDeleteCardModalRequested(!isDeleteCardModalRequested)
  }

  const handleShowMethods = () => {
    setIsSelectedCard(false)
    setIsSelectedPaymentMethod(true)
  }

  const handleGoBack = () => {
    setIsSelectedCard(true)
    setIsSelectedPaymentMethod(false)
  }

  const handleOpenDeleteGCModal = (card: IGiftCard) => {
    setGiftCardToDelete(card)
    setOpenDeleteGCModal(true)
  }

  const handleToggleConfirmTermsAndConditions = () => {
    setShowConfirmTermsAndConditions(!showConfirmTermsAndConditions)
  }

  const handleOpenUnipayModal = () => {
    setIsSelectedPaymentMethod(false)
    setIsOpenUnipayModal(true)
  }

  const handleContinuePaymentUniPay = async ({
    rut,
    simulatePaymentData
  }: {
    rut: string
    simulatePaymentData?:
      | IPostSimulationPaymentUnipayResponse
      | Record<string, never>
  }) => {
    setOtpData((prev) => {
      return { ...prev, isLoading: true }
    })
    const requestOtpData = await getUnipayGenerateSecurityCode({
      data: { rut }
    })
    if (requestOtpData.status === 200) {
      setOtpData((prev) => {
        return {
          ...prev,
          ...requestOtpData?.data,
          simulatePaymentData: {
            ...prev?.simulatePaymentData,
            ...simulatePaymentData
          },
          rut,
          isLoading: false
        }
      })
      setIsOpenUnipayModal(false)
      setIsOpenOTP(true)
      trigger({
        eventType: 'checkoutToast',
        data: {
          portalRootId: 'portal-otp',
          type: 'info',
          show: true,
          toastMessage: TOAST_MESSAGES_MODAL_UNIPAY_OTP.info
        }
      })
    }
    setOtpData((prev) => {
      return { ...prev, isLoading: false }
    })
  }

  const handleCloseOTP = () => {
    setIsOpenOTP(false)
  }

  const handleBackToUniPay = () => {
    setIsOpenUnipayModal(true)
    setIsOpenOTP(false)
  }

  useEvents({
    eventType: 'cannotDeleteCard',
    callBack: ({ detail: { show } }) => {
      setOpenModalAlertMembership(show)
    }
  })

  if (checkoutConfig.isLoading) {
    return <PaymentMethodLoader />
  }

  return (
    <>
      {showConfirmTermsAndConditions && (
        <ModalConfirmTermsAndConditions
          handleConfirm={() => {
            mutateCheckoutConfig({ termsAndConditions: true })
            handleToggleConfirmTermsAndConditions()
            !hasSelectedCard && handleOpenModal(false)
            hasSelectedCard && handleToggleListPaymentCardsModal(false)
          }}
          handleToggle={handleToggleConfirmTermsAndConditions}
        />
      )}
      {isSelectedPaymentMethod && (
        <ModalListNewPayments
          handleGoBack={filterCards?.length > 0 ? handleGoBack : undefined}
          handleOpenUnipayModal={handleOpenUnipayModal}
          handleToggle={handleToggleSelectPaymentMethod}
        />
      )}

      {isSelectedCard && (
        <ModalListPaymentsCards
          handleShowMethods={handleShowMethods}
          handleToggle={handleToggleListPaymentCardsModal}
          handleToggleDeleteCardModal={handleToggleDeleteCardModal}
        />
      )}

      {isDeleteCardModalRequested && (
        <ModalDeleteCard
          cardId={currentCardId}
          handleToggleDeleteCardModal={handleToggleDeleteCardModal}
        />
      )}

      {openModalAlertMembership && (
        <ModalAlertMembership
          onClose={() => {
            return setOpenModalAlertMembership(false)
          }}
        />
      )}

      {openDeleteGCModal && (
        <ModalDeleteGiftCard
          deleteGCModalImage={deleteGCModalImage}
          giftCardToDelete={giftCardToDelete}
          isOpen={openDeleteGCModal}
          onClose={() => {
            return setOpenDeleteGCModal(false)
          }}
        />
      )}

      {isOpenUnipayModal && (
        <ModalUnipay
          handleContinue={handleContinuePaymentUniPay}
          handleToggle={() => {
            setIsOpenUnipayModal(false)
          }}
          isLoadingPayment={otpData.isLoading}
        />
      )}

      {isOpenOTP && (
        <ModalOTPUnipayWrapper
          data={otpData}
          handleGoBack={handleBackToUniPay}
          handleResentCodeUnipay={handleContinuePaymentUniPay}
          handleToggle={handleCloseOTP}
        />
      )}

      <BigScreen>
        <Column
          customClassName={styles.container}
          gap={20}
          tagName='section'
        >
          <Banner handleOpenUnipay={handleOpenUnipayModal} />
          <Row tagName='article'>
            <Text
              fontSize='xl'
              fontWeight='medium'
              id={getBemId('paymentMethods', 'title', 'desktop')}
              lineHeight='19px'
            >
              ¿Cómo te gustaría pagar?
            </Text>
          </Row>
          <Row tagName='article'>
            <Column
              gap={20}
              padding='0 0 20px 0'
            >
              {!hasSelectedCard && (
                <Row
                  alignItems='center'
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  clickable='pointer'
                  customClassName={styles['option-method-card']}
                  id={getBemId('payment-methods', 'choose-card')}
                  justifyContent='between'
                  maxHeight='74px'
                  onClick={handleOpenModal}
                  padding='16px'
                >
                  <Row
                    alignItems='center'
                    clickable='pointer'
                    gap={12}
                  >
                    <Picture
                      hasURL
                      height='42px'
                      id={getBemId(
                        'paymentMethods',
                        'selectCardImage',
                        'deskop'
                      )}
                      src='/images/card.png'
                      width='42px'
                    />
                    <Text
                      clickable='pointer'
                      id={getBemId(
                        'paymentMethods',
                        'selectCardText',
                        'desktop'
                      )}
                      lineHeight='17px'
                    >
                      Seleccionar un medio de pago
                    </Text>
                  </Row>
                  <Row
                    clickable='pointer'
                    id={getBemId('paymentMethods', 'selectCardIcon', 'desktop')}
                    maxWidth='max-content'
                  >
                    <Icon
                      clickable='pointer'
                      color={getGlobalStyle('--color-icons-gray')}
                      customSize={18}
                      name='ArrowRight'
                    />
                  </Row>
                </Row>
              )}
              {/* show card selected */}
              {hasSelectedCard && (
                <CardSelected
                  handleToggleSelectedCard={handleToggleListPaymentCardsModal}
                />
              )}
              {/* giftcard */}
              <Row position='relative'>
                {showNotificationBubble && (
                  <NotificationBubble
                    arrowOnTop
                    arrowPosition='left'
                    customLeft='auto'
                    customRight='16px'
                    customTop='54px'
                    fontSize='sm'
                    label='💡 ¡Ya puedes usar tu Giftcard colaborador!'
                    minWidth='247px'
                    onClick={() => {
                      trigger({
                        eventType: 'toggleToastGiftCardNominative',
                        data: { show: 'false' }
                      })
                      trigger({ eventType: 'toggleModalGifcard' })
                    }}
                  />
                )}
                <GifcardComponent
                  handleOpenDeleteGCModal={handleOpenDeleteGCModal}
                  showNotificationBubble={showNotificationBubble}
                />
              </Row>
            </Column>
          </Row>
        </Column>
      </BigScreen>
      <SmallScreen>
        <>
          <Banner handleOpenUnipay={handleOpenUnipayModal} />
          <Column
            borderEdge='bottom'
            customClassName={styles.container}
            gap={16}
            padding='0 0 16px 0'
          >
            <Row>
              <Text
                fontSize='lg'
                fontWeight='medium'
                id={getBemId('paymentMethods', 'title', 'mobile')}
                lineHeight='19px'
              >
                ¿Cómo te gustaría pagar?
              </Text>
            </Row>

            <Column gap={16}>
              {!hasSelectedCard && (
                <Row
                  alignItems='center'
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  clickable='pointer'
                  customClassName={styles['option-method-card']}
                  id={getBemId('payment-methods', 'choose-card')}
                  justifyContent='between'
                  onClick={handleOpenModal}
                  padding='16px'
                >
                  <Row
                    alignItems='center'
                    clickable='pointer'
                    gap={12}
                  >
                    <Picture
                      height='29px'
                      id={getBemId(
                        'paymentMethods',
                        'selectCardImage',
                        'mobile'
                      )}
                      src='/images/card.png'
                      width='29px'
                    />
                    <Text
                      fontSize='md'
                      id={getBemId(
                        'paymentMethods',
                        'selectCardText',
                        'mobile'
                      )}
                      lineHeight='17px'
                    >
                      Seleccionar un medio de pago
                    </Text>
                  </Row>
                  <Row
                    clickable='pointer'
                    id={getBemId('paymentMethods', 'selectCardIcon', 'mobile')}
                    maxWidth='max-content'
                  >
                    <Icon
                      clickable='pointer'
                      color={getGlobalStyle('--color-icons-gray')}
                      customSize={18}
                      name='ArrowRight'
                    />
                  </Row>
                </Row>
              )}
              {/* show card selected */}
              {hasSelectedCard && (
                <CardSelected
                  handleToggleSelectedCard={handleToggleListPaymentCardsModal}
                />
              )}
              {/* giftcard */}
              <GifcardComponent
                handleOpenDeleteGCModal={handleOpenDeleteGCModal}
                showNotificationBubble={showNotificationBubble}
              />
            </Column>
          </Column>
        </>
      </SmallScreen>
    </>
  )
}
