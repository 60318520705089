import { useEffect, useState } from 'react'
import { getContentFulImage } from '@smu-chile/pkg-unimarc-hooks'
import { ANIMATIONS_FILES_TRANSITION } from 'shared/constants'

const useTransactionAnimation = () => {
  const [transactionAnimation, setTransactionAnimation] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchAnimation = async () => {
      setLoading(true)
      try {
        const url = getContentFulImage(
          ANIMATIONS_FILES_TRANSITION?.TRANSACTION_ANIMATION?.ID,
          ANIMATIONS_FILES_TRANSITION?.TRANSACTION_ANIMATION?.SECOND_ID,
          ANIMATIONS_FILES_TRANSITION?.TRANSACTION_ANIMATION?.NAME
        )

        const response = await fetch(url)
        if (!response.ok) throw new Error('Network response was not ok')

        const json = await response.json()
        setTransactionAnimation(json)
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError('An unknown error occurred')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchAnimation()
  }, [])

  return { transactionAnimation, loading, error }
}

export default useTransactionAnimation
