import {
  Spacer,
  Row,
  Column,
  Icon,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { replaceStrings } from '@smu-chile/pkg-unimarc-hooks'

interface TotalUnipayProps {
  totalOrder: string
  totalUnipay: string
}

export const TotalUnipay = ({
  totalUnipay,
  totalOrder
}: TotalUnipayProps): React.ReactElement => {
  const totalUnipayIsLessThanTotal =
    replaceStrings(totalUnipay) < replaceStrings(totalOrder)
  const priceColor = totalUnipayIsLessThanTotal ? 'primary' : 'black'

  return (
    <Row
      alignItems='center'
      id={getBemId('totalUnipay', 'container')}
      justifyContent='between'
    >
      <Column
        maxWidth='177px'
        minWidth='177px'
      >
        <Text
          color='black'
          fontWeight='medium'
          id={getBemId('totalUnipay', 'text')}
        >
          Total Unipay
        </Text>
      </Column>
      <Column width='fit-content'>
        <Row alignItems='center'>
          <div id={getBemId('totalUnipayIcon', 'container')}>
            <Icon
              height={16.8}
              name='Unipay2'
              width={40}
            />
          </div>
          <Spacer.Vertical size={4} />
          <Text
            color={priceColor}
            fontWeight='medium'
            id={getBemId('totalUnipayPrice', 'text')}
          >
            {totalUnipay}
          </Text>
        </Row>
      </Column>
    </Row>
  )
}
