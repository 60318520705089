import Image from 'next/image'
import {
  Column,
  DesktopModalWrapper,
  Text,
  MobileModalWrapper,
  Icon,
  Row,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  postCard,
  replaceStrings,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'
import {
  CODE_ONECLICK_CREDIT,
  CODE_ONECLICK_DEBIT,
  PAYMENT_METHODS_ORDER_TO_SHOW,
  PAYMENT_PROVIDERS,
  PAYMENT_PROVIDERS_DICTIONARY_CODE
} from 'shared/constants'
import { CardItem } from '../CardItem'
import styles from '../styles.module.css'

interface IModalListNewPaymentsProps {
  handleToggle: () => void
  handleGoBack?: () => void
  handleOpenUnipayModal?: () => void
}

const title = 'Seleccionar medio de pago'

export const ModalListNewPayments = ({
  handleToggle,
  handleGoBack,
  handleOpenUnipayModal
}: IModalListNewPaymentsProps) => {
  const { data: checkout } = useCheckoutV2()
  // get max price
  const paymentMethods = checkout?.paymentInfo?.availablePaymentMethods

  // find the oneClick payment method
  const oneClickPrice = replaceStrings(
    paymentMethods?.find((method) => {
      return [CODE_ONECLICK_DEBIT, CODE_ONECLICK_CREDIT].includes(method.id)
    })?.price
  )

  const groupPaymentMethods =
    PAYMENT_METHODS_ORDER_TO_SHOW.map((item) => {
      const findMethod =
        paymentMethods?.find((method) => {
          return item?.codes?.includes(method.id)
        }) || null

      if (!findMethod) return null

      const price = replaceStrings(findMethod?.price) || 0
      const hasDiscount = Boolean(price < oneClickPrice)

      return {
        ...findMethod,
        ...item,
        hasDiscount,
        provider: PAYMENT_PROVIDERS_DICTIONARY_CODE[findMethod.id]
      }
    })?.filter((item) => {
      return item
    }) || []

  const handleSelectPaymentMethod = async (paymentId: string) => {
    const providerToSend = PAYMENT_PROVIDERS_DICTIONARY_CODE[paymentId]
    const getDomain = window.location.origin

    if (providerToSend === PAYMENT_PROVIDERS.UNIPAY) {
      handleOpenUnipayModal && handleOpenUnipayModal()
      return
    }

    const response = await postCard({
      provider: providerToSend,
      inscriptionErrorUrl: `${getDomain}?inscriptionError=true`,
      inscriptionSuccessUrl: `${getDomain}?inscriptionSuccess=true`
    })

    if (typeof response === 'string') {
      const temporalDiv = document.createElement('div')
      temporalDiv.id = 'temporalDiv'
      temporalDiv.innerHTML = response
      document?.body?.appendChild(temporalDiv)
      document?.getElementById('temporalDiv')?.querySelector('form')?.submit()
    }
  }

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId='modalListNewPaymentsDesktop'
          bodyCustomClass={styles.container_body}
          hasfooter={false}
          headerBackButtonProps={{
            onBack: handleGoBack,
            icon: {
              customSize: 20
            }
          }}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerCustomClass={styles.container_header}
          headerTitle={title}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxHeight: '440px',
            minHeight: '440px',
            isOpen: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          styleProps={{
            customHeightModalContent: '100%'
          }}
        >
          <Column customHeight='100%'>
            <Column
              alignItems='center'
              gap={12}
            >
              {groupPaymentMethods?.map((item) => {
                return (
                  <CardItem
                    image={item?.image}
                    key={item.name}
                    onClick={() => {
                      handleSelectPaymentMethod(item.id)
                    }}
                    showDiscountColor={item?.hasDiscount}
                    subTitle={item?.subtitle}
                    title={item?.title}
                    total={item.price}
                  />
                )
              })}

              <Column
                backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='161px'
                gap={16}
                margin='0 0 30px 0'
                minWidth='380px'
                padding='16px 12px'
              >
                <Column
                  customHeight='60px'
                  gap={10}
                >
                  <Row gap={4}>
                    <Icon
                      color={getGlobalStyle('--color-feedback-warning-light')}
                      customSize={20}
                      name='Shield'
                    />
                    <Text
                      fontSize='md'
                      fontWeight='semibold'
                    >
                      Seguridad de pago
                    </Text>
                  </Row>
                  <Column>
                    <Text fontSize='sm'>
                      No compartimos los datos de tus tarjetas con nadie. Tu
                      información de pago está segura con nosotros.
                    </Text>
                  </Column>
                </Column>
                <Column alignItems='center'>
                  <Picture
                    height='53px'
                    id={getBemId('cards', 'methods-payment')}
                    nextImage={Image}
                    objectFit='cover'
                    src='/images/MethodsPayment.png'
                    width='356px'
                  />
                </Column>
              </Column>
            </Column>
          </Column>
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId='modalListNewPaymentsMobile'
          body={
            <Column
              alignItems='center'
              customHeight='100%'
              gap={16}
            >
              {groupPaymentMethods?.map((item) => {
                return (
                  <CardItem
                    image={item?.image}
                    key={item.name}
                    onClick={() => {
                      handleSelectPaymentMethod(item.id)
                    }}
                    showDiscountColor={item?.hasDiscount}
                    subTitle={item?.subtitle}
                    title={item?.title}
                    total={item.price}
                  />
                )
              })}

              <Column
                backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='161px'
                gap={16}
                minWidth='350px'
                padding='16px 12px'
              >
                <Column gap={10}>
                  <Row gap={4}>
                    <Icon
                      color={getGlobalStyle('--color-feedback-warning-light')}
                      customSize={20}
                      name='Shield'
                    />
                    <Text
                      fontSize='md'
                      fontWeight='semibold'
                    >
                      Seguridad de pago
                    </Text>
                  </Row>
                  <Column>
                    <Text fontSize='sm'>
                      No compartimos los datos de tus tarjetas con nadie.
                    </Text>
                    <Text fontSize='sm'>
                      Tu información de pago está segura con nosotros.
                    </Text>
                  </Column>
                </Column>
                <Column alignItems='center'>
                  <Picture
                    alt='pagos'
                    height='53px'
                    nextImage={Image}
                    src='/images/MethodsPayment.png'
                    width='326px'
                  />
                </Column>
              </Column>
              <Row margin='50px 0'>&nbsp;</Row>
            </Column>
          }
          dragProps={{
            draggButton: false
          }}
          hasFooter={false}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          headerTitle={title}
          hideHeaderDivider={true}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
