import { ReactElement, ReactNode, useMemo } from 'react'
import { Context } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface ResponsiveContextProps {
  children: ReactNode
  isMobile: boolean
}

const getWidth = (isMobile: boolean, innerWidth: number): number => {
  if (innerWidth > 0) {
    return innerWidth
  }
  return isMobile ? 780 : 1280
}

export const ResponsiveContext = ({
  isMobile,
  children
}: ResponsiveContextProps): ReactElement => {
  const { innerWidth } = useMobile()
  const width = getWidth(isMobile, innerWidth)

  const contextValue = useMemo(() => {
    return { width }
  }, [width])

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
