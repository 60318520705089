import { useRouter } from 'next/router'
import { Column, Button } from '@smu-chile/pkg-unimarc-components'
import {
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ModalCTAProps {
  onClose: () => void
}

export const ModalCTA = ({ onClose }: ModalCTAProps): React.ReactElement => {
  const router = useRouter()

  const goToMyMembership = () => {
    router.push(
      `${process.env.NEXT_PUBLIC_MEMBERSHIP_URL}?changeMembershipCard=true`
    )
  }

  return (
    <Column gap={16}>
      <Button
        borderRadius={getGlobalStyle('--border-radius-xl')}
        fontWeight='500'
        fullWidth
        id={getBemId('modalAlertMembership', 'primaryButton', 'desktop')}
        label='Ir a Mi membresía'
        onClick={goToMyMembership}
        status='initial'
        type='primary-gray'
      />
      <Button
        background={getGlobalStyle('--color-base-white')}
        border={`1px solid ${getGlobalStyle('--color-base-black')}`}
        borderRadius={getGlobalStyle('--border-radius-xl')}
        color={getGlobalStyle('--color-base-black')}
        fontWeight='500'
        fullWidth
        id={getBemId('modalAlertMembership', 'secondaryyButton', 'desktop')}
        label='Entendido'
        onClick={onClose}
        type='secondary-gray'
      />
    </Column>
  )
}
