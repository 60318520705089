/**
 * Extracts the last four characters from a given card number string.
 * If the input is not a string, it returns an empty string.
 *
 * @param {string} cardNumber - The card number as a string.
 * @returns {string} The last four characters of the card number, or an empty string if the input is not a string.
 *
 * @example
 * // returns '6789'
 * getLastFourNumbersOfCard('123456789');
 *
 * @example
 * // returns ''
 * getLastFourNumbersOfCard(123456789); // TypeScript error ignored in this example
 *
 * @example
 * // returns '123'
 * getLastFourNumbersOfCard('123');
 */
export const getLastFourNumbersOfCard = (cardNumber: string) => {
  if (typeof cardNumber === 'string') return cardNumber.slice(-4) || ''
  return ''
}
