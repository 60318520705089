import { Row, Text } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const ModalUnipayTotal = ({ totalValue }: { totalValue: string }) => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  return (
    <Row
      alignItems='center'
      justifyContent='between'
      padding={isMobile ? '0 24px 16px 24px' : '0 0 16px 0'}
    >
      <Text
        fontSize='base'
        fontWeight='medium'
        id={getBemId('modalUnipayTotal', 'title')}
        lineHeight='normal'
      >
        Total
      </Text>
      <Text
        fontSize='base'
        fontWeight='medium'
        id={getBemId('modalUnipayTotal', 'value')}
        lineHeight='normal'
      >
        {totalValue}
      </Text>
    </Row>
  )
}
