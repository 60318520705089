import {
  Column,
  Container,
  Icon,
  IconNames,
  Row,
  Spacer,
  Text,
  Tooltip as Toast
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { toastConfigs } from '../helpers/configs'
import { TPortalProps } from '../helpers/interfaces'

export interface IToastSuccess {
  toastMessage: string
  iconName?: IconNames
  portalProps?: Partial<TPortalProps>
  handleOnClosed: () => void
}

export const ToastSuccess = ({
  toastMessage,
  portalProps,
  handleOnClosed
}: IToastSuccess): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 768
  const top = isMobile ? '62px' : '12px'
  const { position, width, rightMobile, paddingModal } = toastConfigs()

  return (
    <Toast
      alignAllItems='center'
      background={getGlobalStyle('--color-feedback-success-bg')}
      borderRadius={getGlobalStyle('--border-radius-2sm')}
      boxShadow={getGlobalStyle('--box-shadow-3md')}
      closeColor={getGlobalStyle('--color-icons-black')}
      closeIcon
      display='flex'
      height='56px'
      iconMargin='1px'
      id={getBemId('toastSuccess', 'container')}
      maxWidth='377px'
      onClick={handleOnClosed}
      padding={paddingModal}
      position={position}
      positionContainer='absolute'
      right={rightMobile}
      sizeCloseIcon={14}
      textComponent={
        <Container>
          <Row
            alignItems='center'
            gap='8px'
          >
            <Column
              id={getBemId('toastSuccess', 'icon')}
              maxWidth='max-content'
            >
              <Icon
                color={getGlobalStyle('--color-feedback-success-light')}
                customSize={20}
                name='CheckCircle'
              />
            </Column>
            <Spacer customSize={8} />
            <Column>
              <Text
                color='black'
                fontSize='md'
                fontWeight='medium'
                id={getBemId('toastSuccess', 'text')}
                lineHeight='15px'
                truncate={74}
              >
                {toastMessage}
              </Text>
            </Column>
            <Spacer customSize={10} />
          </Row>
        </Container>
      }
      top={top}
      type='bottom'
      width={width}
      {...portalProps}
    />
  )
}
