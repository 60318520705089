import { useEffect, useRef, useState } from 'react'
import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  patchGiftcards,
  replaceStrings,
  sleep,
  trigger,
  useCards,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'
import styles from '../styles.module.css'
import { BodyModal, IBodyModalProps } from './BodyModal'
import { FooterModal, IFooterModalProps } from './FooterModal'
import { KIND_OF_GIFTCARD } from 'shared/constants'
import { constants, parseGiftCard } from './shared'
import { IGiftCard } from '../GifcardComponent'

export interface IModalGifcardProps {
  onClose: () => void
  handleOpenDeleteGCModal: (card: IGiftCard) => void
}

export const ModalGifcard = ({
  onClose,
  handleOpenDeleteGCModal
}: IModalGifcardProps) => {
  const [values, setValues] = useState(constants.INITIAL_VALUES)
  const [isPinEnabled, setIsPinEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addNewCard, setAddNewCard] = useState(false)
  const [avalibleGiftCards, setAvalibleGiftcards] = useState([])
  const [isLoadingChooseGiftCard, setIsLoadingChooseGiftCard] = useState(false)
  const initialSlide = useRef(0)
  const { data: checkout, temporalMutate } = useCheckoutV2()
  const { data: dataCards } = useCards({ version: 2 })

  const TITLE = addNewCard
    ? constants.TITLE_ADD_NEW
    : constants.TITLE_SHOW_GIFTCARDS
  const isFormCompleted =
    values.cardNumber?.length === constants.MAX_LENGTH_CARD_NUMBER &&
    values.pin?.length >= constants.MIN_LENGTH_PIN &&
    values.pin?.length <= constants.MAX_LENGTH_PIN

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fieldValue = event.target.value

    if (
      event.target.name === 'cardNumber' &&
      fieldValue?.replace(/-/g, '')?.length > 18
    ) {
      return
    } else if (event.target.name === 'cardNumber') {
      fieldValue = parseGiftCard(fieldValue)
    } else if (
      event.target.name === 'pin' &&
      addNewCard &&
      (fieldValue?.length > constants.MAX_LENGTH_PIN ||
        !fieldValue?.match(/^[0-9]*$/))
    ) {
      return
    }

    setValues((prev) => {
      return {
        ...prev,
        [event.target.name]: fieldValue
      }
    })
  }

  const handleToggleAddNewCard = () => {
    setValues(constants.INITIAL_VALUES)
    setAddNewCard((prev) => {
      return !prev
    })
  }

  const handleContinueAction = async () => {
    const checkIfExist = checkout?.paymentInfo?.giftCards?.some((card) => {
      return card.cardNumber === values?.cardNumber?.replace(/-/g, '')
    })
    if (checkIfExist) {
      onClose()
      return
    }
    setIsLoading(true)
    setIsLoadingChooseGiftCard(true)
    const response = await patchGiftcards({
      data: [
        {
          cardNumber: values?.cardNumber?.replace(/-/g, ''),
          pin: values.pin,
          active: true,
          document: checkout?.profile?.document
        }
      ]
    })

    if (response?.status === 200) {
      temporalMutate({
        paymentInfo: {
          ...checkout?.paymentInfo,
          selectedPayments: null,
          giftCards: response?.data?.giftcards ?? []
        },
        totals: response?.data?.totals,
        value: response?.data?.value
      })
      if (addNewCard) {
        trigger({
          eventType: 'checkoutToast',
          data: {
            show: true,
            success: true,
            toastMessage: '¡Tu Giftcard fue ingresada con éxito!'
          }
        })
      }
    } else {
      trigger({
        eventType: 'checkoutToast',
        data: {
          codeError: response?.data?.code ?? 'Error',
          show: true,
          toastMessage:
            'No fue posible ingresar tu Giftcard. Intenta nuevamente.'
        }
      })
    }

    window?.scrollTo(0, 0)
    setIsLoading(false)
    setIsLoadingChooseGiftCard(false)
    setValues(constants.INITIAL_VALUES)
    onClose()
  }

  const handleClose = () => {
    setValues(constants.INITIAL_VALUES)
    onClose()
  }

  const handleChooseGiftCard = async (index: number) => {
    setValues((prev) => {
      return {
        ...prev,
        cardNumber: parseGiftCard(avalibleGiftCards[index]?.card),
        type: avalibleGiftCards[index]?.type,
        currentIndex: index
      }
    })
    setIsLoadingChooseGiftCard(true)
    await sleep(500)
    setIsLoadingChooseGiftCard(false)
  }

  useEffect(() => {
    setIsPinEnabled(
      values.cardNumber?.length === constants.MAX_LENGTH_CARD_NUMBER
    )
  }, [values])

  useEffect(() => {
    const handlePreventDefault = (event: KeyboardEvent) => {
      if (event.key === '-' || event.key === '.') {
        event.preventDefault()
      }
    }

    window?.addEventListener('keydown', handlePreventDefault)

    return () => {
      window?.removeEventListener('keydown', handlePreventDefault)
    }
  }, [])

  useEffect(() => {
    if (
      !isValidArrayWithData(checkout?.paymentInfo?.giftCards) &&
      !isValidArrayWithData(dataCards?.nominatives)
    ) {
      setAddNewCard(true)
      return
    }

    if (
      isValidArrayWithData(checkout?.paymentInfo?.giftCards) ||
      isValidArrayWithData(dataCards?.nominatives)
    ) {
      // get general giftcards and merge with nominative giftcards
      const getBaseGiftCards = checkout?.paymentInfo?.giftCards
        ?.map((giftCard) => {
          const checkIfExist = dataCards?.nominatives.some((card) => {
            return card.card === giftCard.cardNumber
          })

          if (checkIfExist) {
            return null
          }

          return {
            card: giftCard.cardNumber,
            balance: giftCard.balance,
            type: KIND_OF_GIFTCARD.GIFTCARD
          }
        })
        .filter(Boolean)
      const getNominativeGiftcard = dataCards?.nominatives?.map((card) => {
        return {
          card: card.card,
          balance: card.balance,
          type: KIND_OF_GIFTCARD.NOMINATIVA
        }
      })
      // sort by balance
      const giftCardsMerged = [
        ...getBaseGiftCards.slice(),
        ...getNominativeGiftcard.slice()
      ].sort((a, b) => {
        return replaceStrings(b.balance) - replaceStrings(a.balance)
      })

      if (isValidArrayWithData(giftCardsMerged)) {
        const findIndexCurrentCard = giftCardsMerged.findIndex((card) => {
          return card.card === checkout?.paymentInfo?.giftCards?.[0]?.cardNumber
        })
        const indexNominative =
          findIndexCurrentCard < 0 ? 0 : findIndexCurrentCard
        initialSlide.current = indexNominative

        setAvalibleGiftcards(giftCardsMerged)
        setValues((prev) => {
          return {
            ...prev,
            type: giftCardsMerged[indexNominative]?.type,
            cardNumber: parseGiftCard(giftCardsMerged[indexNominative]?.card),
            balance: giftCardsMerged[indexNominative]?.balance
          }
        })
      }
    }
  }, [checkout, dataCards])

  const bodyModalProps: IBodyModalProps = {
    addNewCard,
    avalibleGiftCards,
    isPinEnabled: isPinEnabled && addNewCard,
    values,
    isCardNumberEnabled: addNewCard,
    showPin:
      values.type.toUpperCase() === KIND_OF_GIFTCARD.GIFTCARD || addNewCard,
    isLoadingChooseGiftCard,
    initialSlide: initialSlide.current,
    handleChange,
    handleChooseGiftCard,
    setValues,
    handleOpenDeleteGCModal
  }

  const footerModalProps: IFooterModalProps = {
    addNewCard,
    isFormCompleted,
    isLoading,
    isLoadingChooseGiftCard,
    handleChooseGiftCard: handleContinueAction,
    handleContinueAction,
    handleToggleAddNewCard
  }

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId='modal-gifcard'
          bodyChildrenProps={{
            maxHeight: addNewCard ? '440px' : '384px',
            padding: '0 0 12px'
          }}
          footerChildrenProps={{
            default: false,
            children: <FooterModal {...footerModalProps} />
          }}
          headerCloseIcon={{
            customSize: 20,
            default: true
          }}
          headerCustomClass={styles.container_header}
          headerTitle={TITLE}
          headerTitleProps={{
            fontWeight: 'semibold'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxWidth: '440px',
            minHeight: '580px',
            isOpen: true,
            toggle: handleClose,
            toggleOutside: handleClose
          }}
        >
          <BodyModal {...bodyModalProps} />
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId='modal-gifcard'
          body={<BodyModal {...bodyModalProps} />}
          bodyContainerProps={{
            padding: '0',
            maxHeight: 'calc(100vh - 280px)'
          }}
          customFooter={<FooterModal {...footerModalProps} />}
          dragProps={{
            draggButton: false
          }}
          hasShadow={false}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          headerTitle={TITLE}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: onClose,
            toggleOutside: onClose
          }}
          onClose={handleClose}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
