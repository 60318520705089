import { ReactNode } from 'react'
import {
  Column,
  RadioSelector,
  RadioSelectorProps,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './styles.module.css'

export interface ICardRadioSelectorProps {
  radioSelectorProps: RadioSelectorProps
  title: ReactNode
  description: ReactNode
  id: string
}

export const CardRadioSelector = ({
  radioSelectorProps,
  title,
  description,
  id
}: ICardRadioSelectorProps) => {
  return (
    <>
      <BigScreen>
        <Row
          alignItems='center'
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius={getGlobalStyle('--border-radius-2sm')}
          gap={12}
          id={getBemId('radioSelector', 'container', id)}
          padding='16px'
        >
          <Row maxWidth='max-content'>
            <RadioSelector
              customClassName={styles.radioSelector}
              {...radioSelectorProps}
            />
          </Row>
          <Column>
            <Row>{title}</Row>
            <Row>{description}</Row>
          </Column>
        </Row>
      </BigScreen>
      <SmallScreen>
        <Row
          alignItems='center'
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          gap={8}
          id={getBemId('radioSelector', 'container', id)}
          padding='8px 12px'
        >
          <Column>
            <Row>{title}</Row>
            <Row gap={2}>{description}</Row>
          </Column>
          <Row maxWidth='max-content'>
            <RadioSelector
              customClassName={styles.radioSelector}
              {...radioSelectorProps}
            />
          </Row>
        </Row>
      </SmallScreen>
    </>
  )
}
