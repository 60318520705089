import {
  Column,
  DesktopModalWrapper,
  MobileModalWrapper,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'
import { useState } from 'react'
import { TOAST_MESSAGES } from 'shared/constants'
import { ModalPrivacyPolicy } from '../ModalPrivacyPolicy'
import { ModalTermsAndConditions } from '../ModalTermsAndConditions'

export interface IModalConfirmTermsAndConditionsProps {
  handleToggle: () => void
  handleConfirm: () => void
}

export const ModalConfirmTermsAndConditions = ({
  handleToggle,
  handleConfirm
}: IModalConfirmTermsAndConditionsProps) => {
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState(false)
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)

  const handleToggleTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(!isTermsAndConditionsOpen)
  }

  const handleTogglePrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen)
  }

  const handleClose = (showMessage = false) => {
    if (showMessage) {
      trigger({
        eventType: 'checkoutToast',
        data: {
          type: 'info',
          success: true,
          show: true,
          toastMessage: TOAST_MESSAGES.info_terms_and_conditions
        }
      })
    }
    handleToggle()
  }

  if (isTermsAndConditionsOpen) {
    return (
      <ModalTermsAndConditions handleToggle={handleToggleTermsAndConditions} />
    )
  }

  if (isPrivacyPolicyOpen) {
    return <ModalPrivacyPolicy handleToggle={handleTogglePrivacyPolicy} />
  }

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={getBemId('modal', 'confirm-terms-and-conditions')}
          bodyChildrenProps={{
            padding: '0',
            overflow: 'hidden',
            customHeight: '159px',
            justifyContent: 'center'
          }}
          footerChildrenProps={{
            default: true,
            padding: '12px 16px',
            buttonDefaultProps: {
              onClick: handleConfirm,
              height: '40px',
              fontWeight: getGlobalStyle('--font-weight-medium'),
              fontSize: getGlobalStyle('--font-size-base')
            }
          }}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerContainerProps={{
            borderEdge: 'none'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            isOpen: true,
            toggleOutside: handleClose,
            toggle: handleClose,
            maxWidth: '375px',
            maxHeight: '275px',
            level: '1'
          }}
        >
          <Column
            alignItems='center'
            customHeight='145px'
            gap={8}
            padding='2px 0'
          >
            <Row
              alignItems='center'
              justifyContent='center'
              padding='8px 12px'
            >
              <Text
                fontSize='xl'
                fontWeight='semibold'
              >
                ¡Falta poco!
              </Text>
            </Row>
            <Row
              gap={2}
              isWrap
              justifyContent='center'
              padding='8px 23px'
            >
              <Text fontSize='base'>Para continuar comprando en Unimarc,</Text>
              <Text fontSize='base'>deberás aceptar los</Text>
              <Text
                fontSize='base'
                fontWeight='medium'
                id={getBemId('summary', 'terms-and-conditions')}
                onClick={handleToggleTermsAndConditions}
                underline='always'
              >
                términos y
              </Text>
              <Text
                fontSize='base'
                fontWeight='medium'
                id={getBemId('summary', 'terms-and-conditions')}
                onClick={handleToggleTermsAndConditions}
                underline='always'
              >
                condiciones
              </Text>
              <Text fontSize='base'>y las </Text>
              <Text
                fontSize='base'
                fontWeight='medium'
                id={getBemId('summary', 'privacy-policy')}
                onClick={handleTogglePrivacyPolicy}
                underline='always'
              >
                políticas de privacidad.
              </Text>
            </Row>
          </Column>
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId={getBemId('modal', 'confirm-terms-and-conditions')}
          body={
            <Column
              alignItems='center'
              customHeight='145px'
              gap={8}
              padding='2px 0'
            >
              <Row
                alignItems='center'
                justifyContent='center'
                padding='8px 12px'
              >
                <Text
                  fontSize='xl'
                  fontWeight='semibold'
                >
                  ¡Falta poco!
                </Text>
              </Row>
              <Row
                gap={2}
                isWrap
                justifyContent='center'
                padding='8px 23px'
              >
                <Text fontSize='base'>
                  Para continuar comprando en Unimarc,
                </Text>
                <Text fontSize='base'>deberás aceptar los</Text>
                <Text
                  fontSize='base'
                  fontWeight='medium'
                  id={getBemId('summary', 'terms-and-conditions')}
                  onClick={handleToggleTermsAndConditions}
                  underline='always'
                >
                  términos y
                </Text>
                <Text
                  fontSize='base'
                  fontWeight='medium'
                  id={getBemId('summary', 'terms-and-conditions')}
                  onClick={handleToggleTermsAndConditions}
                  underline='always'
                >
                  condiciones
                </Text>
                <Text fontSize='base'>y las </Text>
                <Text
                  fontSize='base'
                  fontWeight='medium'
                  id={getBemId('summary', 'privacy-policy')}
                  onClick={handleTogglePrivacyPolicy}
                  underline='always'
                >
                  políticas de privacidad.
                </Text>
              </Row>
            </Column>
          }
          buttonConfigsProps={{
            label: 'Aceptar',
            onClick: handleConfirm
          }}
          dragProps={{
            draggButton: false
          }}
          hasShadow={false}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            halfScreen: true,
            minHeightFullScreen: '275px',
            maxHeightFullScreen: '275px',
            toggle: handleClose,
            toggleOutside: handleClose
          }}
          onClose={handleClose}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
