import { Button } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const ErrorReloadPageButton = (): React.ReactElement => {
  const { isMobile } = useMobile()
  const errorButtonLabel = 'Recargar página'
  const errorButtonMaxWidth = isMobile ? '343px' : '408px'
  const typeButton = 'primary-gray'

  const handdleReloadPage = () => {
    window.location.reload()
  }

  return (
    <>
      <Button
        borderRadius={getGlobalStyle('--border-radius-xl')}
        fontWeight='500'
        height='40px'
        label={errorButtonLabel}
        maxWidth={errorButtonMaxWidth}
        onClick={handdleReloadPage}
        type={typeButton}
        width='100%'
      />
    </>
  )
}
