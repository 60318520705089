export const checkMobileUserAgent = (userAgent: string): boolean => {
  return Boolean(
    userAgent?.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  )
}

interface DeviceProps {
  isMobile: boolean
  innerWidth: number
}

// check between mobile device or mobile desktop view
export const checkDevice = ({ isMobile, innerWidth }: DeviceProps): boolean => {
  return isMobile || innerWidth < 1280
}
