/* eslint-disable react/jsx-sort-props */
import Head from 'next/head'
import parse from 'html-react-parser'
import { organizationSchema, webSiteSchema } from './schema'
import { normalizeString } from '@smu-chile/pkg-unimarc-hooks'

export interface ProductProps {
  availability?: string
  brand?: string
  description?: string
  images?: Array<string>
  name?: string
  price: number
  sku?: string
}

interface HeaderProps {
  canonicalPath?: string
  categories?: Array<string>
  contentDescription?: string
  metaRobot?: string
  pageImg?: string
  pageNumber?: string
  productInfo?: ProductProps
  titleName?: string
  type?:
    | 'HOME'
    | 'PLP'
    | 'PDP'
    | 'SRP'
    | 'COLLECTION'
    | 'STATIC'
    | 'LANDING'
    | 'BRAND'
    | 'CU'
}

export const MetaHead = ({
  canonicalPath = '',
  contentDescription = '',
  metaRobot = 'index,follow',
  pageNumber,
  productInfo,
  titleName = '',
  type = 'HOME'
}: HeaderProps) => {
  const customTitle = titleName ? parse(`${titleName} | Unimarc`) : 'Unimarc'
  const contentTitle = titleName ? `${titleName} | Unimarc` : 'Unimarc'
  const productListTitle = pageNumber
    ? parse(`${titleName} - Página ${pageNumber} | Unimarc`)
    : null
  const canonicalUrl = `https://www.unimarc.cl${normalizeString(canonicalPath)}`
  const metaRobotTag =
    canonicalUrl.includes('login') ||
    canonicalUrl.includes('gclid') ||
    type === 'SRP'
      ? 'noindex,follow'
      : metaRobot

  return (
    <Head>
      <title>{productListTitle ?? customTitle}</title>

      {/* 1. Landings & static pages "page title"
          2. PLP page title or empty
          3. PDP produtc description
      */}
      <meta
        name='description'
        content={productInfo?.description ?? contentDescription}
      />

      {/* Sitewide default value for all pages */}
      <meta
        property='og:type'
        content='website'
      />
      <meta
        property='og:site_name'
        content='Unimarc.cl'
      />

      {/* default value for all pages */}
      <meta
        name='robots'
        content={
          process.env.NEXT_PUBLIC_ENV === 'labs' ? 'noindex' : metaRobotTag
        }
      />

      {/* Same title as <Title /> */}
      <meta
        property='og:title'
        content={contentTitle}
      />

      {/* Same value as Canonical or actual url  */}
      <meta
        property='og:url'
        content={canonicalUrl}
      />

      {/* Same value as Meta description*/}
      <meta
        property='og:description'
        content={productInfo?.description ?? contentDescription}
      />

      {/* Twitter default value */}
      <meta
        name='twitter:card'
        content='summary'
      />
      <meta
        name='twitter:site'
        content='@Unimarc'
      />

      {/* Viewport default value */}
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
      />
      <link
        href='/favicon.ico'
        rel='icon'
      />

      {/* url "https://www.unimarc.cl/{pageName}"
        1. PDP url + pagination
        2. SRP excluded from canonical
        3. PLP url without query params
       */}
      {type !== 'SRP' && (
        <link
          href={canonicalUrl}
          rel='canonical'
        />
      )}
      {type === 'HOME' && (
        <>
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: webSiteSchema }}
          />
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: organizationSchema }}
          />
        </>
      )}
    </Head>
  )
}
