import { useRef } from 'react'
import {
  Column,
  Icon,
  InputLabel,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  normalizeString
} from '@smu-chile/pkg-unimarc-hooks'
import { Card } from '../Card'
import { CarouselGiftCards } from '../CarouselGiftCards'
import { IGiftCard } from '../../GifcardComponent'
import { KIND_OF_GIFTCARD } from 'shared/constants'

export interface IBodyModalProps {
  addNewCard?: boolean
  avalibleGiftCards: {
    card: string
    balance: string
    type: string
  }[]
  isCardNumberEnabled?: boolean
  isPinEnabled?: boolean
  showPin?: boolean
  isLoadingChooseGiftCard?: boolean
  initialSlide?: number
  values: {
    cardNumber: string
    pin: string
    balance: string
    currentIndex: number
  }
  handleChooseGiftCard: (index: number) => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setValues: React.Dispatch<
    React.SetStateAction<{ cardNumber: string; pin: string }>
  >
  handleOpenDeleteGCModal: (card: IGiftCard) => void
}

export const BodyModal = ({
  addNewCard = false,
  avalibleGiftCards,
  isCardNumberEnabled = true,
  isPinEnabled = true,
  showPin = true,
  values,
  initialSlide = 0,
  isLoadingChooseGiftCard,
  handleChange,
  handleChooseGiftCard,
  setValues,
  handleOpenDeleteGCModal
}: IBodyModalProps) => {
  const counter_gc = useRef({
    normal: 0,
    nominative: 0
  })

  const showOneCard = addNewCard || avalibleGiftCards?.length === 1
  const showMoreThanOneCard =
    !showOneCard &&
    isValidArrayWithData(avalibleGiftCards) &&
    avalibleGiftCards?.length > 1

  return (
    <Column
      alignItems='center'
      customHeight='100%'
      gap={32}
    >
      <Column
        alignItems='center'
        justifyContent='center'
      >
        <Spacer.Horizontal customSize={24} />
        {showOneCard && (
          <Column
            alignItems='center'
            customHeight='max-content'
            customWidth='max-content'
            position='relative'
            top='0'
          >
            <Card
              balance={values?.balance}
              cardNumber={values.cardNumber}
              giftCardType={
                addNewCard
                  ? KIND_OF_GIFTCARD.GIFTCARD
                  : avalibleGiftCards?.[0]?.type
              }
              handleOpenDeleteGCModal={handleOpenDeleteGCModal}
              key={
                addNewCard
                  ? KIND_OF_GIFTCARD.GIFTCARD
                  : avalibleGiftCards?.[0]?.type
              }
              showDeleteIcon={
                normalizeString(avalibleGiftCards?.[0]?.type) ===
                normalizeString(KIND_OF_GIFTCARD.GIFTCARD)
              }
            />
          </Column>
        )}
        {showMoreThanOneCard && (
          <CarouselGiftCards
            handleChooseGiftCard={handleChooseGiftCard}
            initialSlide={initialSlide}
          >
            {isValidArrayWithData(avalibleGiftCards)
              ? avalibleGiftCards.map((card, cardIndex) => {
                  const index =
                    card.type === KIND_OF_GIFTCARD.GIFTCARD
                      ? counter_gc.current.normal++
                      : counter_gc.current.nominative++
                  const isSelectedCard = values?.currentIndex === cardIndex
                  const valuesToShow = {
                    balance: isSelectedCard ? card?.balance : '',
                    cardNumber: isSelectedCard ? card?.card : ''
                  }

                  return (
                    <Card
                      {...valuesToShow}
                      giftCardType={card?.type}
                      handleOpenDeleteGCModal={handleOpenDeleteGCModal}
                      index={index}
                      key={card?.card}
                      showDeleteIcon={
                        normalizeString(card?.type) ===
                        normalizeString(KIND_OF_GIFTCARD.GIFTCARD)
                      }
                    />
                  )
                })
              : []}
          </CarouselGiftCards>
        )}
      </Column>
      <Column
        alignItems='center'
        gap={32}
        justifyContent='center'
        padding='1.5px 16px'
      >
        <InputLabel
          disabled={!isCardNumberEnabled}
          disabledText={!isCardNumberEnabled}
          height={44}
          inputMode='numeric'
          inputWidth='360px'
          isLoading={isLoadingChooseGiftCard}
          label={
            addNewCard ? 'Ingresa número de Giftcard' : 'Número de Giftcard'
          }
          name='cardNumber'
          onChange={handleChange}
          onClickIcon={() => {
            if (!isCardNumberEnabled) return null
            return setValues((prev) => {
              return { ...prev, cardNumber: '' }
            })
          }}
          showXIcon={addNewCard}
          value={values.cardNumber}
          variant='compact'
        />
        {showPin && (
          <InputLabel
            disabled={!isPinEnabled || !addNewCard}
            disabledText={!isPinEnabled || !addNewCard}
            height={44}
            inputMode={addNewCard ? 'numeric' : 'text'}
            inputWidth='360px'
            isLoading={isLoadingChooseGiftCard}
            label='Pin de Giftcard'
            name='pin'
            onChange={handleChange}
            onClickIcon={() => {
              if (!isPinEnabled) return null
              return setValues((prev) => {
                return { ...prev, pin: '' }
              })
            }}
            showXIcon={addNewCard}
            value={addNewCard ? values.pin : '**** ****'}
            variant='compact'
          />
        )}
      </Column>
      <Row
        alignItems='center'
        border={`0.3px solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
        borderRadius={getGlobalStyle('--border-radius-2sm')}
        customHeight='60px'
        gap={9}
        maxWidth='343px'
        padding='12px 8px'
      >
        <Row maxWidth='max-content'>
          <Icon
            color={getGlobalStyle('--color-feedback-info')}
            customSize={24}
            name='InfoFillThin'
          />
        </Row>
        <Row
          gap={2}
          isWrap
        >
          <Text
            display='initial'
            fontSize='md'
            lineHeight='normal'
            type='span'
          >
            Recuerda que el monto de tu Giftcard debe
          </Text>
          <Text
            display='initial'
            fontSize='md'
            lineHeight='normal'
            type='span'
          >
            ser
          </Text>
          <Text
            fontSize='md'
            fontWeight='semibold'
            lineHeight='normal'
            type='span'
          >
            mayor o igual al total de tu compra.
          </Text>
        </Row>
      </Row>
    </Column>
  )
}
