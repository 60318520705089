import { IToastConfig } from '../interfaces'

export const toastConfigs = (): IToastConfig => {
  const isMobile = window?.innerWidth < 768
  return {
    position: isMobile ? 'relative' : 'sticky',
    topMobile: isMobile ? '62px' : '92px',
    height: '56px',
    maxWidth: '377px',
    width: '92%',
    rightMobile: isMobile ? '0px' : '140px',
    leftMobile: isMobile ? '0px' : '',
    sizeAlertModalIcon: isMobile ? 28 : 24,
    paddingModal: isMobile ? '8px 16px' : '12px 12px 12px 8px'
  }
}
