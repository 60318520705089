import {
  isValidArrayWithData,
  normalizeString,
  ICardResponse,
  replaceStrings
} from '@smu-chile/pkg-unimarc-hooks'
import {
  CODE_ONECLICK_CREDIT,
  CODE_ONECLICK_DEBIT,
  PAYMENT_IMAGES
} from 'shared/constants'
import { getLastFourNumbersOfCard } from '../getLastFourNumbersOfCard'
import { ICheckoutV2Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICheckoutV2'

interface getCardsToListProps {
  checkout: ICheckoutV2Response
  getJustCards: ICardResponse
}

/**
 * Retrieves a list of cards to display, with added information about discounts.
 *
 * @param {Object} props - The function props.
 * @param {Object[]} props.getJustCards - An array of card objects to display.
 * @param {Object} props.checkout - The checkout object containing payment information.
 * @returns {Object[]} - An array of cards with additional information.
 *
 * @example
 * const props = {
 *   getJustCards: [
 *     {
 *       entity: 'VISA',
 *       cardType: 'Credit',
 *       type: 'Debit',
 *       cardNumber: '1234567812345678',
 *       paymentId: '123'
 *     },
 *   ],
 *   checkout: {
 *     paymentInfo: {
 *       availablePaymentMethods: [
 *         {
 *           id: '123',
 *           price: '$10.00'
 *         },
 *       ]
 *     }
 *   }
 * };
 *
 * const cardList = getCardsToList(props)
 */
export const getCardsToList = ({
  getJustCards,
  checkout
}: getCardsToListProps) => {
  // find the oneClick payment method
  const oneClickPrice = replaceStrings(
    checkout?.paymentInfo?.availablePaymentMethods?.find((method) => {
      return [CODE_ONECLICK_DEBIT, CODE_ONECLICK_CREDIT].includes(method.id)
    })?.price
  )
  const cardsToList =
    isValidArrayWithData(getJustCards) &&
    getJustCards.map((item) => {
      const getImage =
        PAYMENT_IMAGES[normalizeString(item?.entity || '')] ||
        PAYMENT_IMAGES[normalizeString(item?.cardType || '')] ||
        PAYMENT_IMAGES[normalizeString(item?.type || '')]
      const subTitle = `****${getLastFourNumbersOfCard(item?.cardNumber)}`
      const providerValues =
        checkout?.paymentInfo?.availablePaymentMethods?.find(
          (paymentMethod) => {
            return paymentMethod?.id === item?.paymentId
          }
        )

      const price = replaceStrings(providerValues?.price) || 0
      // validate card price is less (that means it has discount)
      const hasDiscount = Boolean(price < oneClickPrice)

      return {
        ...item,
        hasDiscount,
        price: providerValues?.price,
        image: getImage,
        subTitle,
        type: normalizeString(item.cardType)
      }
    })

  return cardsToList?.reverse() || []
}
