import { useEffect, useState } from 'react'
import { Column, Row, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useCheckoutConfig } from 'shared/hooks'
import { putShopper, useCheckoutV2 } from '@smu-chile/pkg-unimarc-hooks'
import { CardRadioSelector } from './CardRadioSelector'
import { SaveBagsBackbone } from './SaveBagsBackbone'
import styles from './styles.module.css'

export const SaveBags = () => {
  const [isSaveBags, setIsSaveBags] = useState(false)
  const {
    data: checkoutData,
    isLoading: isLoadingCheckout,
    temporalMutate
  } = useCheckoutV2()
  const { checkoutConfig, mutateCheckoutConfig } = useCheckoutConfig()

  const handleSaveBags = async (value: boolean) => {
    if (value === isSaveBags) return
    setIsSaveBags(value)
    mutateCheckoutConfig({
      loadingTransaction: true,
      isEnablePaymentButton: false
    })
    const responseUpdateBags = (await putShopper({
      data: { wantBags: value }
    })) as { status?: number }
    if (responseUpdateBags?.status === 204) {
      temporalMutate({
        shopperInfo: {
          wantBags: value
        }
      })
    }
    mutateCheckoutConfig({
      loadingTransaction: false,
      isEnablePaymentButton: true
    })
  }

  useEffect(() => {
    if (!isLoadingCheckout) {
      setIsSaveBags(checkoutData?.shopperInfo?.wantBags)
    }
  }, [checkoutData])

  if (checkoutConfig?.isLoading) {
    return <SaveBagsBackbone />
  }

  return (
    <>
      <BigScreen>
        <Column gap={16}>
          <Row id={getBemId('saveBags', 'title')}>
            <Text
              fontSize='xl'
              fontWeight='medium'
            >
              ¿Cuidamos juntos el planeta? 🌎 🌱
            </Text>
          </Row>
          <Column gap={12}>
            <CardRadioSelector
              description={
                <Row gap={2}>
                  <Text
                    customColor={getGlobalStyle('--color-text-gray-light')}
                    fontSize='md'
                  >
                    Usaré mis
                  </Text>
                  <Text
                    customColor={getGlobalStyle(
                      '--color-feedback-success-light'
                    )}
                    fontSize='md'
                    fontWeight='medium'
                  >
                    bolsas reutilizables
                  </Text>
                  <Text
                    customColor={getGlobalStyle('--color-text-gray-light')}
                    fontSize='md'
                  >
                    al momento de recibir mi compra.
                  </Text>
                </Row>
              }
              id='saveBags'
              radioSelectorProps={{
                checked: !isSaveBags,
                id: getBemId('saveBags', 'radio'),
                onClick: () => {
                  return handleSaveBags(false)
                }
              }}
              title={
                <Text
                  fontSize='base'
                  fontWeight='medium'
                >
                  Sí, quiero ayudar al planeta
                </Text>
              }
            />
            <CardRadioSelector
              description={
                <Text
                  customColor={getGlobalStyle('--color-text-gray-light')}
                  fontSize='md'
                >
                  Quiero que empaquen mi compra.
                </Text>
              }
              id='notSaveBags'
              radioSelectorProps={{
                checked: isSaveBags,
                id: getBemId('notSaveBags', 'radio'),
                onClick: () => {
                  return handleSaveBags(true)
                }
              }}
              title={
                <Text
                  fontSize='base'
                  fontWeight='medium'
                >
                  No, quizás en otro momento
                </Text>
              }
            />
          </Column>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column
          customClassName={styles['container']}
          gap={16}
        >
          <Row id={getBemId('saveBags', 'title')}>
            <Text
              fontSize='base'
              fontWeight='medium'
            >
              ¿Cuidamos juntos el planeta? 🌎 🌱
            </Text>
          </Row>
          <Column gap={12}>
            <CardRadioSelector
              description={
                <Row
                  gap={2}
                  isWrap
                >
                  <Text
                    customColor={getGlobalStyle('--color-text-gray-light')}
                    fontSize='sm'
                  >
                    Usaré mis
                  </Text>
                  <Text
                    customColor={getGlobalStyle(
                      '--color-feedback-success-light'
                    )}
                    fontSize='sm'
                    fontWeight='medium'
                  >
                    bolsas reutilizables
                  </Text>
                  <Text
                    customColor={getGlobalStyle('--color-text-gray-light')}
                    fontSize='sm'
                  >
                    al momento de
                  </Text>
                  <Text
                    customColor={getGlobalStyle('--color-text-gray-light')}
                    fontSize='sm'
                  >
                    recibir mi compra.
                  </Text>
                </Row>
              }
              id='saveBags'
              radioSelectorProps={{
                checked: !isSaveBags,
                id: getBemId('saveBags', 'radio'),
                onClick: () => {
                  return handleSaveBags(false)
                }
              }}
              title={
                <Text
                  fontSize='md'
                  fontWeight='medium'
                >
                  Sí, quiero ayudar al planeta
                </Text>
              }
            />
            <CardRadioSelector
              description={
                <Text
                  customColor={getGlobalStyle('--color-text-gray-light')}
                  fontSize='sm'
                >
                  Quiero que empaquen mi compra.
                </Text>
              }
              id='notSaveBags'
              radioSelectorProps={{
                checked: isSaveBags,
                id: getBemId('notSaveBags', 'radio'),
                onClick: () => {
                  return handleSaveBags(true)
                }
              }}
              title={
                <Text
                  fontSize='md'
                  fontWeight='medium'
                >
                  No, quizás en otro momento
                </Text>
              }
            />
          </Column>
          <Spacer.Horizontal customSize={8} />
        </Column>
      </SmallScreen>
    </>
  )
}
