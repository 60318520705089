import { Column, Row, Backbone } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const PayBtnLoader = () => {
  return (
    <Column gap={20}>
      <Row>
        <Backbone
          borderRadius={getGlobalStyle('--border-radius-xl')}
          height={44}
        />
      </Row>
    </Column>
  )
}
