import Image from 'next/image'
import {
  Column,
  Icon,
  IconProps,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import classNames from 'classnames'
import { THandleToggleDeleteCardModal } from '../helpers'
import styles from '../styles.module.css'

export type TCardItemProps = {
  image: string
  amountOfCards?: number
  cardId?: string
  title: string
  subTitle: string
  total: string
  iconProps?: Partial<IconProps>
  isActive?: boolean
  showDiscountColor?: boolean
  handleToggleDeleteCardModal?: THandleToggleDeleteCardModal
  onClick?: () => void
}

const getImageType = (image: string) => {
  return image?.split('cards_images/')?.[1]?.split('.')?.[0]
}

export const CardItem = ({
  cardId,
  image,
  title,
  subTitle,
  total,
  iconProps,
  isActive,
  showDiscountColor,
  onClick,
  handleToggleDeleteCardModal
}: TCardItemProps) => {
  const imageType = getImageType(image)
  const getPriceColor = showDiscountColor
    ? getGlobalStyle('--color-text-primary')
    : getGlobalStyle('--color-base-black')

  return (
    <Row
      alignItems='center'
      borderRadius={getGlobalStyle('--border-radius-md')}
      clickable='pointer'
      customClassName={classNames(styles['option-method-card'], {
        [styles['option-method-card_active']]: isActive
      })}
      gap={8}
      maxHeight='70px'
      minHeight='70px'
      onClick={onClick}
      padding='16px'
      tagName='article'
    >
      <Column
        clickable='pointer'
        maxWidth='max-content'
      >
        <Picture
          alt='Logo'
          height='30px'
          id={getBemId('cards', 'image', imageType)}
          nextImage={Image}
          src={image}
          width='30px'
        />
      </Column>
      <Column
        clickable='pointer'
        id={getBemId('cards', 'info', imageType)}
      >
        <Text
          clickable='pointer'
          fontSize='md'
          fontWeight='medium'
        >
          {title}
        </Text>
        <Text
          clickable='pointer'
          fontSize='sm'
        >
          {subTitle}
        </Text>
      </Column>
      <Column
        id={getBemId('cards', 'total', imageType)}
        maxWidth='max-content'
      >
        <Text
          clickable='pointer'
          customColor={getPriceColor}
          fontSize='lg'
          fontWeight='medium'
        >
          {total}
        </Text>
      </Column>
      <Column
        id={getBemId('cards', 'delete', imageType)}
        maxWidth='max-content'
        onClick={() => {
          return handleToggleDeleteCardModal(cardId)
        }}
      >
        <Icon
          color={getGlobalStyle('--color-base-black')}
          customSize={24}
          name='ArrowRightNavigate2'
          {...iconProps}
        />
      </Column>
    </Row>
  )
}
