import { Column, Row, Backbone } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const CarouselProductsLoader = () => {
  return (
    <Column
      borderRadius={getGlobalStyle('--border-radius-md')}
      customHeight='160px'
      gap='16px'
      padding='16px'
    >
      <Row maxWidth='134px'>
        <Backbone
          borderRadius={getGlobalStyle('--border-radius-md')}
          height={14}
        />
      </Row>
      <Row gap={16}>
        {Array.from(Array(7).keys()).map((item) => {
          return (
            <Row
              key={item}
              maxWidth='60px'
            >
              <Backbone height={60} />
            </Row>
          )
        })}
      </Row>
    </Column>
  )
}
