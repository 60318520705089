import { getContentfulData, QueryClient } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Props for the `parseContentfulData` function.
 */
export interface IParseContentfulDataProps {
  cfData?: any
  makeRequest?: boolean
  queryClient?: QueryClient
}

/**
 * Parses the contentful data and cleans the fields and resolves links.
 *
 * @param mainData - The main data to be parsed.
 * @param options - The options for parsing the contentful data.
 * @returns The parsed and cleaned data.
 */
export async function parseContentfulData(
  mainData: any,
  {
    cfData = undefined,
    makeRequest = true,
    queryClient
  }: IParseContentfulDataProps = {}
) {
  const data = mainData || cfData

  /**
   * Cleans an individual entry.
   *
   * @param entry - The entry to be cleaned.
   * @param includes - The includes data.
   * @returns The cleaned entry.
   */
  async function cleanEntry(entry: any, includes: any) {
    const cleanedEntry = {
      id: entry?.sys?.id,
      type: entry?.sys?.type,
      createdAt: entry?.sys?.createdAt,
      updatedAt: entry?.sys?.updatedAt,
      contentType: entry?.sys?.contentType?.sys?.id,
      locale: entry?.sys?.locale,
      fields: await cleanFields(entry.fields, includes)
    }
    return cleanedEntry
  }

  /**
   * Cleans the fields and resolves links.
   *
   * @param fields - The fields to be cleaned.
   * @param includes - The includes data.
   * @returns The cleaned fields.
   */
  async function cleanFields(fields: any, includes: any) {
    const cleanedFields = {}
    for (const [key, value] of Object.entries(fields)) {
      if (Array.isArray(value)) {
        cleanedFields[key] = await Promise.all(
          value.map(async (item) => {
            return await resolveLink(item, includes)
          })
        )
      } else {
        cleanedFields[key] = await resolveLink(value, includes)
      }
    }
    return cleanedFields
  }

  /**
   * Resolves a link to its entry.
   *
   * @param link - The link to be resolved.
   * @param includes - The includes data.
   * @returns The resolved link.
   */
  async function resolveLink(link: any, includes: any) {
    if (link.sys && link.sys.type === 'Link') {
      if (link.sys.linkType === 'Entry') {
        const linkedEntry = includes.Entry.find((item: any) => {
          return item.sys.id === link.sys.id
        })
        if (linkedEntry) {
          return await cleanEntry(linkedEntry, includes)
        }
      }

      if (link.sys.linkType === 'Asset') {
        const linkedAsset = includes.Asset.find((item: any) => {
          return item.sys.id === link.sys.id
        })

        if (linkedAsset) {
          return await cleanEntry(linkedAsset, includes)
        }
      }

      // if not found, try to make a request
      if (makeRequest) {
        return await resolveExternalLink(link)
      }

      return link.sys
    }
    return link
  }

  /**
   * Resolves an external link.
   *
   * @param link - The link to be resolved.
   * @returns The resolved link.
   */
  async function resolveExternalLink(link: any) {
    let requestData

    if (queryClient) {
      requestData = queryClient.getQueryData(['contentfulData', link.sys.id])
      if (!requestData) {
        requestData = await queryClient.fetchQuery(
          ['contentfulData', link.sys.id],
          async () => {
            return await getContentfulData({
              options: {
                'sys.id': link.sys.id
              }
            })
          }
        )
      }
    } else {
      requestData = await getContentfulData({
        options: {
          'sys.id': link.sys.id
        }
      })
    }

    if (requestData?.items?.length) {
      return await cleanEntry(requestData?.items?.[0], requestData?.includes)
    }

    return link.sys
  }

  // Clean the main items
  const cleanedItems = await Promise.all(
    data?.items?.map(async (item: any) => {
      return await cleanEntry(item, data.includes)
    }) ?? []
  )

  // Return the cleaned structure
  return {
    total: data?.total,
    skip: data?.skip,
    limit: data?.limit,
    items: cleanedItems,
    includes: data?.includes
  }
}
