import { useState } from 'react'
import {
  Column,
  Container,
  Divider,
  Icon,
  Row,
  Spacer,
  Text,
  ToggleSwitch
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  removeCurrencyFormat,
  useCheckoutV2,
  toCamelCase
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'shared/hooks'
import {
  CODE_UNIPAY_WITH_INSTALLMENTS,
  CODE_UNIPAY_WITHOUT_INSTALLMENTS
} from 'shared/constants'
import { ModalTermsAndConditions, ModalPrivacyPolicy } from '../'
import { SummaryBackbone } from './SummaryBackbone'
import { TotalUnipay } from './TotalUnipay'
import styles from './styles.module.css'

export interface IContentfulRichText {
  items: [{ fields: { value: { content: [] } } }]
}

export interface SummaryProps {
  isSelectCheck: boolean
  handleCheck: (value: boolean) => void
}

const MEMBERSIP_LEVELS_ICONS_SUMMARY = {
  DiscountsMembership: {
    name: 'DiamondMembership',
    backgroundColor: getGlobalStyle('--color-background-peacock-blue'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 21 21',
    pathTransform: 'translate(0, -1)',
    heigth: 14,
    width: 14
  },
  DiscountsPlatinum: {
    name: 'CrownIconMembership',
    backgroundColor: getGlobalStyle('--color-background-air-superiority-blue'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 20 25',
    heigth: 14,
    width: 15
  },
  DiscountsGold: {
    name: 'HeartIconMembership',
    backgroundColor: getGlobalStyle('--color-background-dark-orange2'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 35 25',
    pathTransform: 'translate(0.5, 3.5)',
    heigth: 15,
    width: 19
  },
  DiscountsUnimarcClub: {
    name: 'WinkyIconMembership',
    backgroundColor: getGlobalStyle('--color-background-pacific-blue'),
    color: getGlobalStyle('--color-base-white'),
    viewBoxProps: '0 0 25 25',
    pathTransform: 'translate(1.5, 2.5)',
    heigth: 15,
    width: 15
  }
}

export const Summary = ({ isSelectCheck, handleCheck }: SummaryProps) => {
  // UI
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState(false)
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)
  // services
  const { mutateCheckoutConfig, checkoutConfig } = useCheckoutConfig()
  const { data, isLoading: isLoadingCheckout } = useCheckoutV2()

  const isLoading = checkoutConfig.isLoading

  // CALCS for summary
  const total = data?.value
  const totalsSummary =
    (isValidArrayWithData(data?.totals) &&
      data?.totals?.filter((total) => {
        return (
          parseInt(removeCurrencyFormat(total.amount)) !== 0 &&
          !/total de/.test(total.name.toLowerCase()) &&
          !/despacho/.test(total.name.toLowerCase())
        )
      })) ||
    []
  const totalShipping = data?.totals?.find((total) => {
    return total.id === 'Shipping'
  })?.amount
  const findTotalUnipay = data?.paymentInfo?.availablePaymentMethods?.find(
    (method) => {
      return (
        method?.id === CODE_UNIPAY_WITHOUT_INSTALLMENTS ||
        method?.id === CODE_UNIPAY_WITH_INSTALLMENTS
      )
    }
  ) ?? { price: '0' }

  const handleSelectCheck = (value: boolean) => {
    mutateCheckoutConfig({
      termsAndConditions: value,
      isLoading: isLoadingCheckout
    })
    handleCheck(value)
  }

  const handleToggleTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(!isTermsAndConditionsOpen)
  }

  const handleTogglePrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen)
  }

  if (isLoading) {
    return <SummaryBackbone />
  }

  return (
    <>
      {isTermsAndConditionsOpen && (
        <ModalTermsAndConditions
          handleToggle={handleToggleTermsAndConditions}
        />
      )}
      {isPrivacyPolicyOpen && (
        <ModalPrivacyPolicy handleToggle={handleTogglePrivacyPolicy} />
      )}
      <BigScreen>
        <Container
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          customHeight='max-content'
          gap={12}
          maxHeight='543px'
          padding='16px'
        >
          <Column alignItems='center'>
            <Column
              gap={13}
              padding='16px'
            >
              <Row
                alignItems='center'
                gap={10}
              >
                <Text
                  fontWeight='medium'
                  id={getBemId('summary', 'item', 'count-summary')}
                >
                  Resumen
                </Text>
              </Row>
              <Column
                gap={8}
                id={getBemId('summary', 'container-totals-summary')}
              >
                {isValidArrayWithData(totalsSummary) &&
                  totalsSummary.map(({ name, amount, id }) => {
                    const membershipIcon = MEMBERSIP_LEVELS_ICONS_SUMMARY[id]

                    return (
                      <Row
                        alignItems='center'
                        justifyContent='between'
                        key={id}
                      >
                        {membershipIcon ? (
                          <Row alignItems='center'>
                            <Text
                              customColor={getGlobalStyle(
                                '--color-neutral-black'
                              )}
                              fontSize='md'
                            >
                              {name}
                            </Text>
                            <Spacer.Vertical customSize={4} />
                            <Column
                              alignItems='center'
                              backgroundColor={membershipIcon?.backgroundColor}
                              borderRadius='50%'
                              id={getBemId(
                                'membership-icon',
                                membershipIcon?.name
                              )}
                              justifyContent='center'
                              key={membershipIcon?.name}
                              maxWidth='20px'
                              minHeight='20px'
                            >
                              <Icon
                                color={membershipIcon?.color}
                                height={membershipIcon?.heigth}
                                name={membershipIcon?.name}
                                pathTransform={membershipIcon?.pathTransform}
                                viewBoxProp={membershipIcon?.viewBoxProps}
                                width={membershipIcon?.width}
                              />
                            </Column>
                          </Row>
                        ) : (
                          <Text
                            customColor={getGlobalStyle(
                              '--color-neutral-black'
                            )}
                            fontSize='md'
                            id={getBemId('summary', 'item', toCamelCase(name))}
                          >
                            {name}
                          </Text>
                        )}
                        <Text
                          customColor={getGlobalStyle('--color-neutral-black')}
                          fontSize='md'
                        >
                          {amount}
                        </Text>
                      </Row>
                    )
                  })}
                <Row
                  customClassName={styles['pill-delivery-wrapper']}
                  id={getBemId('summary', 'container-delivery')}
                  justifyContent='between'
                >
                  <Text
                    customColor={getGlobalStyle('--color-neutral-black')}
                    fontSize='md'
                    id={getBemId('summary', 'item', 'shipping')}
                  >
                    Despacho
                  </Text>
                  {parseInt(removeCurrencyFormat(totalShipping)) === 0 ? (
                    <Container
                      backgroundColor={getGlobalStyle(
                        '--color-background-mountain-meadow'
                      )}
                      borderRadius={getGlobalStyle('--border-radius-full')}
                      maxWidth='max-content'
                      padding='4px'
                    >
                      <Text
                        customColor={getGlobalStyle('--color-background-jade')}
                        fontSize='md'
                        fontWeight='semibold'
                      >
                        Gratis 🥳
                      </Text>
                    </Container>
                  ) : (
                    <Text
                      customColor={getGlobalStyle('--color-neutral-black')}
                      fontSize='md'
                    >
                      {totalShipping}
                    </Text>
                  )}
                </Row>
              </Column>
              <Divider
                color={getGlobalStyle('--color-neutral-gray-divider')}
                thickness={0}
                verticalMargin={0}
              />
              <Column
                gap={12}
                id={getBemId('summary', 'container-total')}
              >
                <Row justifyContent='between'>
                  <Text
                    fontWeight='medium'
                    id={getBemId('summary', 'item', 'total')}
                  >
                    Total
                  </Text>
                  <Text fontWeight='medium'>{total}</Text>
                </Row>
                {parseInt(removeCurrencyFormat(findTotalUnipay?.price)) !==
                  0 && (
                  <TotalUnipay
                    totalOrder={total}
                    totalUnipay={findTotalUnipay?.price}
                  />
                )}
              </Column>
            </Column>
            <Column
              gap={12}
              maxWidth='319px'
            >
              <Row
                backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
                borderRadius={getGlobalStyle('--border-radius-md')}
                gap={2}
                hidden
                isWrap
                maxHeight='46px'
                maxWidth='316px'
                padding='8px'
              >
                <Text
                  fontSize='sm'
                  lineHeight='15px'
                >
                  Los jueves con tu tarjeta
                </Text>
                <Text
                  fontSize='sm'
                  fontWeight='semibold'
                  lineHeight='15px'
                >
                  Banco Estado ahorra hasta
                </Text>
                <Text
                  fontSize='sm'
                  fontWeight='semibold'
                  lineHeight='15px'
                >
                  un 30%
                </Text>
                <Text
                  fontSize='sm'
                  lineHeight='15px'
                >
                  en el total de tu boleta.🐥💳
                </Text>
              </Row>
              <Row
                alignItems='center'
                id={getBemId('summary', 'container-terms-and-conditions')}
                justifyContent='between'
              >
                <Row
                  gap={2}
                  isWrap
                >
                  <Text
                    color='gray-light'
                    fontSize='sm'
                    lineHeight='normal'
                  >
                    Acepto los
                  </Text>
                  <Text
                    clickable='pointer'
                    fontSize='sm'
                    fontWeight='semibold'
                    id={getBemId('summary', 'terms-and-conditions')}
                    lineHeight='normal'
                    onClick={handleToggleTermsAndConditions}
                  >
                    términos y condiciones
                  </Text>
                  <Text
                    color='gray-light'
                    fontSize='sm'
                    lineHeight='normal'
                  >
                    y las
                  </Text>
                  <Text
                    clickable='pointer'
                    fontSize='sm'
                    fontWeight='semibold'
                    id={getBemId('summary', 'privacy-policy')}
                    lineHeight='normal'
                    onClick={handleTogglePrivacyPolicy}
                  >
                    políticas de privacidad
                  </Text>
                </Row>
                <Row
                  id={getBemId(
                    'summary',
                    'container-toggle-switch-terms-and-conditions'
                  )}
                  maxWidth='max-content'
                >
                  <ToggleSwitch
                    customClass={styles['toggle-switch']}
                    onClick={() => {
                      return handleSelectCheck(!isSelectCheck)
                    }}
                    value={!isSelectCheck}
                  />
                </Row>
              </Row>
            </Column>
          </Column>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          borderRadius={getGlobalStyle('--border-radius-md')}
          gap={12}
          margin='0 0 10px 0'
        >
          <Column gap={12}>
            <Row gap={10}>
              <Text
                fontWeight='medium'
                id={getBemId('summary', 'item', 'count-summary')}
              >
                Resumen
              </Text>
            </Row>
            <Column
              gap={8}
              id={getBemId('summary', 'container-totals-summary')}
            >
              {isValidArrayWithData(totalsSummary) &&
                totalsSummary.map(({ name, amount, id }) => {
                  const membershipIcon = MEMBERSIP_LEVELS_ICONS_SUMMARY[id]

                  return (
                    <Row
                      justifyContent='between'
                      key={id}
                    >
                      {membershipIcon ? (
                        <Row alignItems='center'>
                          <Text
                            customColor={getGlobalStyle(
                              '--color-neutral-black'
                            )}
                            fontSize='md'
                          >
                            {name}
                          </Text>
                          <Spacer.Vertical customSize={4} />
                          <Column
                            alignItems='center'
                            backgroundColor={membershipIcon?.backgroundColor}
                            borderRadius='50%'
                            id={getBemId(
                              'membership-icon',
                              membershipIcon?.name
                            )}
                            justifyContent='center'
                            key={membershipIcon?.name}
                            maxWidth='20px'
                            minHeight='20px'
                          >
                            <Icon
                              color={membershipIcon?.color}
                              height={membershipIcon?.heigth}
                              name={membershipIcon?.name}
                              pathTransform={membershipIcon?.pathTransform}
                              viewBoxProp={membershipIcon?.viewBoxProps}
                              width={membershipIcon?.width}
                            />
                          </Column>
                        </Row>
                      ) : (
                        <Text
                          customColor={getGlobalStyle('--color-neutral-black')}
                          fontSize='md'
                        >
                          {name}
                        </Text>
                      )}
                      <Text
                        customColor={getGlobalStyle('--color-neutral-black')}
                        fontSize='md'
                        whiteSpace='nowrap'
                      >
                        {amount}
                      </Text>
                    </Row>
                  )
                })}
              <Row
                customClassName={styles['pill-delivery-wrapper']}
                id={getBemId('summary', 'container-delivery')}
                justifyContent='between'
              >
                <Text
                  customColor={getGlobalStyle('--color-neutral-black')}
                  fontSize='md'
                >
                  Despacho
                </Text>
                {parseInt(removeCurrencyFormat(totalShipping)) === 0 ? (
                  <Container
                    backgroundColor={getGlobalStyle(
                      '--color-background-mountain-meadow'
                    )}
                    borderRadius={getGlobalStyle('--border-radius-full')}
                    maxWidth='max-content'
                    padding='4px'
                  >
                    <Text
                      customColor={getGlobalStyle('--color-background-jade')}
                      fontSize='md'
                      fontWeight='semibold'
                    >
                      Gratis 🥳
                    </Text>
                  </Container>
                ) : (
                  <Text
                    customColor={getGlobalStyle('--color-neutral-black')}
                    fontSize='md'
                  >
                    {totalShipping}
                  </Text>
                )}
              </Row>
              <Divider
                borderHeight='3xs'
                thickness={0}
                verticalMargin={0}
              />
              <Column
                gap={12}
                id={getBemId('summary', 'container-total')}
              >
                <Row justifyContent='between'>
                  <Text
                    fontWeight='medium'
                    id={getBemId('summary', 'item', 'total')}
                  >
                    Total
                  </Text>
                  <Text fontWeight='medium'>{total}</Text>
                </Row>
                {parseInt(removeCurrencyFormat(findTotalUnipay?.price)) !==
                  0 && (
                  <TotalUnipay
                    totalOrder={total}
                    totalUnipay={findTotalUnipay?.price}
                  />
                )}
              </Column>
            </Column>
            <Column>
              <Row
                backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
                borderRadius={getGlobalStyle('--border-radius-md')}
                gap={2}
                hidden
                isWrap
                maxHeight='46px'
                padding='8px'
              >
                <Text
                  fontSize='sm'
                  lineHeight='normal'
                >
                  Los jueves con tu tarjeta
                </Text>
                <Text
                  fontSize='sm'
                  fontWeight='semibold'
                  lineHeight='15px'
                >
                  Banco Estado ahorra hasta
                </Text>
                <Text
                  fontSize='sm'
                  fontWeight='semibold'
                  lineHeight='15px'
                >
                  un 30%
                </Text>
                <Text
                  fontSize='sm'
                  lineHeight='15px'
                >
                  en el total de tu boleta.🐥💳
                </Text>
              </Row>
            </Column>
            <Row
              id={getBemId('summary', 'container-terms-and-conditions')}
              justifyContent='between'
            >
              <Row
                gap={2}
                isWrap
                maxWidth='80%'
              >
                <Text
                  color='gray-light'
                  fontSize='sm'
                >
                  Acepto los
                </Text>
                <Text
                  clickable='pointer'
                  fontSize='sm'
                  fontWeight='semibold'
                  id={getBemId('summary', 'terms-and-conditions')}
                  onClick={handleToggleTermsAndConditions}
                >
                  términos y condiciones
                </Text>
                <Text
                  color='gray-light'
                  fontSize='sm'
                >
                  y las
                </Text>
                <Text
                  clickable='pointer'
                  fontSize='sm'
                  fontWeight='semibold'
                  id={getBemId('summary', 'privacy-policy')}
                  onClick={handleTogglePrivacyPolicy}
                >
                  políticas de privacidad
                </Text>
              </Row>
              <Row
                id={getBemId(
                  'summary',
                  'container-toggle-switch-terms-and-conditions'
                )}
                maxWidth='max-content'
              >
                <ToggleSwitch
                  customClass={styles['toggle-switch']}
                  onClick={() => {
                    return handleSelectCheck(!isSelectCheck)
                  }}
                  value={!isSelectCheck}
                />
              </Row>
            </Row>
          </Column>
        </Container>
      </SmallScreen>
    </>
  )
}
