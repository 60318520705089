import { Button, Column, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface IFooterModalProps {
  addNewCard?: boolean
  isFormCompleted?: boolean
  isLoading?: boolean
  isLoadingChooseGiftCard?: boolean
  handleChooseGiftCard?: () => void
  handleContinueAction?: () => void
  handleToggleAddNewCard?: () => void
}

export const FooterModal = ({
  addNewCard,
  isFormCompleted,
  isLoading,
  isLoadingChooseGiftCard,
  handleChooseGiftCard,
  handleContinueAction,
  handleToggleAddNewCard
}: IFooterModalProps) => {
  if (addNewCard) {
    return (
      <Button
        borderRadius='24px'
        fullWidth
        id={getBemId(`modal-gifcard-desktop-modal-wrapper`, 'button')}
        isLoading={isLoading}
        label='Continuar'
        onClick={handleContinueAction}
        padding='8px 16px'
        status={isFormCompleted ? 'initial' : 'disabled'}
      />
    )
  }

  return (
    <Column>
      <Button
        borderRadius='24px'
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId(`modal-gifcard-desktop-modal-wrapper`, 'button-use-gc')}
        isLoading={isLoadingChooseGiftCard}
        label='Quiero usar esta Giftcard'
        onClick={handleChooseGiftCard}
        padding='8px 16px'
      />
      <Spacer.Horizontal customSize={16} />
      <Button
        background={getGlobalStyle('--color-base-white')}
        border={`1px solid ${getGlobalStyle('--color-base-black')}`}
        borderRadius='24px'
        color={getGlobalStyle('--color-base-black')}
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId(`modal-gifcard-desktop-modal-wrapper`, 'button-add')}
        label='Agregar nueva Giftcard'
        onClick={handleToggleAddNewCard}
        padding='8px 16px'
      />
    </Column>
  )
}
