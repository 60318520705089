import {
  Column,
  Container,
  Icon,
  IconNames,
  Row,
  Spacer,
  Text,
  Tooltip as Toast
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { toastConfigs } from '../helpers/configs'
import { TPortalProps } from '../helpers/interfaces'

export interface IToastInfo {
  toastMessage: string
  iconName?: IconNames
  portalProps?: Partial<TPortalProps>
  handleOnClosed?: () => void
}
export const ToastInfo = ({
  toastMessage,
  iconName = 'InfoFill',
  portalProps,
  handleOnClosed
}: IToastInfo): React.ReactElement => {
  const {
    position,
    topMobile,
    height,
    maxWidth,
    width,
    rightMobile,
    leftMobile,
    sizeAlertModalIcon,
    paddingModal: padding
  } = toastConfigs()
  return (
    <Toast
      alignAllItems='center'
      background={getGlobalStyle('--color-feedback-info-bg')}
      borderRadius={getGlobalStyle('--border-radius-2sm')}
      boxShadow={getGlobalStyle('--box-shadow-3md')}
      closeColor={getGlobalStyle('--color-neutral-black')}
      closeIcon={true}
      display='flex'
      height={height}
      iconMargin='1px'
      id={getBemId('toastInfo', 'container')}
      left={leftMobile}
      maxWidth={maxWidth}
      onClick={handleOnClosed}
      padding={padding}
      position={position}
      positionContainer='absolute'
      right={rightMobile}
      sizeCloseIcon={14}
      textComponent={
        <Container maxHeight='60px'>
          <Row alignItems='center'>
            <Column
              id={getBemId('toastInfo', 'icon')}
              maxWidth='max-content'
            >
              <Icon
                color={getGlobalStyle('--color-feedback-info')}
                customSize={sizeAlertModalIcon}
                name={iconName}
              />
            </Column>
            <Spacer size={8} />
            <Column maxWidth='max-content'>
              <Text
                color='neutral-black'
                display='initial'
                fontSize='md'
                fontWeight='medium'
                id={getBemId('toastInfo', 'text')}
                lineHeight='normal'
              >
                {toastMessage}
              </Text>
            </Column>
            <Spacer customSize={10} />
          </Row>
        </Container>
      }
      top={topMobile}
      type='bottom'
      width={width}
      {...portalProps}
    />
  )
}
