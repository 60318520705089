import {
  ICardResponse,
  isValidArrayWithData,
  replaceStrings
} from '@smu-chile/pkg-unimarc-hooks'
import { ICheckoutV2Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICheckoutV2'

export interface IValidatePaymentButton {
  checkout: ICheckoutV2Response
  cards: ICardResponse
  termsAndConditions: boolean
}

/**
 * Validates the payment button.
 * @param {IValidatePaymentButton} params - The parameters to validate the payment button.
 * @returns {boolean} - The validation result.
 */
export const validatePaymentButton = ({
  checkout,
  cards,
  termsAndConditions
}: IValidatePaymentButton): boolean => {
  const checkoutValue = replaceStrings(checkout?.value)

  // Finds the gift card selected
  const findGiftCardSelected =
    checkout?.paymentInfo?.giftCards.find((giftCard) => {
      return giftCard.isActive
    }) || {}

  // Validates if there is a gift card with enough amount
  const hasGiftCardWithEnoughAmount =
    !isValidArrayWithData(checkout?.paymentInfo?.selectedPayments) &&
    findGiftCardSelected.hasEnoughAmount

  // Validates if there is a card payment selected
  const isCardPaymentSelected =
    isValidArrayWithData(cards) &&
    isValidArrayWithData(checkout?.paymentInfo?.selectedPayments)

  // Validates if the payment button will be enabled
  const isEnablePaymentButton =
    termsAndConditions &&
    checkoutValue > 0 &&
    (hasGiftCardWithEnoughAmount || isCardPaymentSelected)

  return isEnablePaymentButton
}
