import { Picture, Row, Text } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface ISelectGifcardProps {
  handleToggleGiftCardModal: () => void
}

export const SelectGifcard = ({
  handleToggleGiftCardModal
}: ISelectGifcardProps) => {
  return (
    <>
      <BigScreen>
        <Row
          alignItems='center'
          borderRadius={getGlobalStyle('--border-radius-md')}
          clickable='pointer'
          id={getBemId('select-gifcard', 'container')}
          justifyContent='between'
          maxHeight='74px'
          padding='16px'
        >
          <Row
            alignItems='center'
            clickable='pointer'
            gap={12}
          >
            <Picture
              hasURL
              height='42px'
              src='/images/giftcard.png'
              width='42px'
            />
            <Text
              clickable='pointer'
              fontWeight='medium'
              lineHeight='17px'
            >
              Giftcard
            </Text>
          </Row>
          <Row
            clickable='pointer'
            maxWidth='max-content'
          >
            <Text
              clickable='pointer'
              customColor={getGlobalStyle('--color-primary-light-red')}
              fontWeight='medium'
              id={getBemId('select-gifcard', 'add-button')}
              lineHeight='17px'
              onClick={handleToggleGiftCardModal}
            >
              Agregar
            </Text>
          </Row>
        </Row>
      </BigScreen>
      <SmallScreen>
        <Row
          alignItems='center'
          borderRadius={getGlobalStyle('--border-radius-md')}
          clickable='pointer'
          id={getBemId('select-gifcard', 'container')}
          justifyContent='between'
          padding='16px'
        >
          <Row
            alignItems='center'
            clickable='pointer'
            gap={12}
          >
            <Picture
              height='29px'
              src='/images/giftcard.png'
              width='29px'
            />
            <Text
              fontSize='md'
              fontWeight='medium'
              lineHeight='17px'
            >
              Giftcard
            </Text>
          </Row>
          <Row
            clickable='pointer'
            maxWidth='max-content'
          >
            <Text
              clickable='pointer'
              customColor={getGlobalStyle('--color-primary-light-red')}
              fontSize='md'
              fontWeight='medium'
              id={getBemId('select-gifcard', 'add-button')}
              lineHeight='17px'
              onClick={handleToggleGiftCardModal}
            >
              Agregar
            </Text>
          </Row>
        </Row>
      </SmallScreen>
    </>
  )
}
