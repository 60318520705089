import Image from 'next/image'
import { useState } from 'react'
import {
  Column,
  Picture,
  Row,
  Text,
  ToggleSwitch
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { normalizeString } from '@smu-chile/pkg-unimarc-hooks'
import { PAYMENT_IMAGES, PAYMENT_METHODS } from 'shared/constants'
import { getLastFourNumbersOfCard } from 'shared/helpers/getLastFourNumbersOfCard'
import { useSelectedCard } from 'shared/hooks'
import styles from '../styles.module.css'

interface ICardSelectedProps {
  handleToggleSelectedCard: () => void
}

const CARD_SELECTED_TEXTS = {
  DEBIT: 'Débito',
  CREDIT: 'Crédito',
  PREPAGO: 'Prepago',
  TITLE: 'Mi tarjeta',
  CHANGE_CARD: 'Cambiar',
  SAVE_CARD: '¡Listo! Guardaremos los datos de tu tarjeta'
}

export const CardSelected = ({
  handleToggleSelectedCard
}: ICardSelectedProps) => {
  const [isSelectCheck, setIsSelectCheck] = useState(false)
  const { selectedCard } = useSelectedCard()

  const cardImage =
    PAYMENT_IMAGES[normalizeString(selectedCard?.entity || '')] ||
    PAYMENT_IMAGES[normalizeString(selectedCard?.cardType || '')] ||
    PAYMENT_IMAGES[normalizeString(selectedCard?.type || '')]

  const paymentType = () => {
    if (
      selectedCard?.type === PAYMENT_METHODS.DEBITCARD &&
      selectedCard?.cardType?.toLowerCase() === PAYMENT_METHODS.PREPAGO
    )
      return 'Prepago'
    if (selectedCard?.type === PAYMENT_METHODS.CREDITCARD) return 'Crédito'
    if (selectedCard?.type === PAYMENT_METHODS.DEBITCARD) return 'Débito'
    return ''
  }

  const handleToggleSelectCheck = () => {
    setIsSelectCheck(!isSelectCheck)
  }

  return (
    <>
      <BigScreen>
        <Row
          border={`1px solid ${getGlobalStyle('--color-primary-light-red')}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          id={getBemId('card-selected', 'container')}
          padding='16px'
        >
          <Column gap={16}>
            {/* header */}
            <Row
              justifyContent='between'
              tagName='article'
            >
              <Text
                fontWeight='medium'
                id={getBemId('cardSelected', 'title', 'desktop')}
              >
                {CARD_SELECTED_TEXTS.TITLE}
              </Text>
              <Text
                clickable='pointer'
                customColor={getGlobalStyle('--color-primary-light-red')}
                fontWeight='medium'
                id={getBemId('cardSelected', 'cambiar', 'buttonDesktop')}
                lineHeight='17px'
                onClick={handleToggleSelectedCard}
              >
                {CARD_SELECTED_TEXTS.CHANGE_CARD}
              </Text>
            </Row>
            <Row
              alignItems='center'
              justifyContent='between'
              tagName='article'
            >
              <Row
                alignItems='center'
                gap={12}
              >
                <Row
                  alignItems='center'
                  customHeight='42px'
                  maxWidth='42px'
                >
                  <Picture
                    height='100%'
                    id={getBemId('cardSelected', 'cardImage', 'desktop')}
                    nextImage={Image}
                    src={cardImage}
                    width='100%'
                  />
                </Row>
                <Column gap={4}>
                  <Text
                    fontSize='lg'
                    fontWeight='medium'
                    id={getBemId('cardSelected', 'paymentType', 'desktop')}
                  >
                    {paymentType()}
                  </Text>
                  <Row
                    alignItems='center'
                    maxWidth='max-content'
                  >
                    <Text
                      customColor={getGlobalStyle('--color-icons-gray')}
                      fontSize='md'
                      id={getBemId(
                        'cardSelected',
                        'lastNumberOfCards',
                        'desktop'
                      )}
                      lineHeight='14px'
                    >
                      {`****${
                        getLastFourNumbersOfCard(selectedCard?.cardNumber) || ''
                      }`}
                    </Text>
                  </Row>
                </Column>
              </Row>
              <Row maxWidth='max-content'>
                <Text
                  fontSize='lg'
                  fontWeight='medium'
                >
                  {selectedCard?.valueUsed}
                </Text>
              </Row>
            </Row>
            <Row
              backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
              borderRadius={getGlobalStyle('--border-radius-2sm')}
              hidden
              justifyContent='between'
              padding='8px 16px'
            >
              <Text
                fontSize='md'
                fontWeight='medium'
              >
                {CARD_SELECTED_TEXTS.SAVE_CARD}
              </Text>
              <ToggleSwitch
                customClass={styles['toggle-switch']}
                onClick={handleToggleSelectCheck}
                value={!isSelectCheck}
              />
            </Row>
          </Column>
        </Row>
      </BigScreen>
      <SmallScreen>
        <Row
          border={`1px solid ${getGlobalStyle('--color-primary-light-red')}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          id={getBemId('card-selected', 'container')}
          padding='16px'
        >
          <Column gap={16}>
            <Row justifyContent='between'>
              <Text
                fontWeight='medium'
                id={getBemId('cardSelected', 'title', 'mobile')}
              >
                {CARD_SELECTED_TEXTS.TITLE}
              </Text>
              <Text
                clickable='pointer'
                customColor={getGlobalStyle('--color-primary-light-red')}
                fontWeight='medium'
                id={getBemId('cardSelected', 'cambiar', 'buttonMobile')}
                lineHeight='17px'
                onClick={handleToggleSelectedCard}
              >
                {CARD_SELECTED_TEXTS.CHANGE_CARD}
              </Text>
            </Row>
            <Row
              alignItems='center'
              justifyContent='between'
            >
              <Row
                alignItems='center'
                gap={12}
              >
                <Row
                  alignItems='center'
                  customHeight='30px'
                  justifyContent='center'
                  maxWidth='30px'
                >
                  <Picture
                    height='100%'
                    id={getBemId('cardSelected', 'cardImage', 'mobile')}
                    nextImage={Image}
                    src={cardImage}
                    width='100%'
                  />
                </Row>
                <Column gap={4}>
                  <Text
                    fontSize='lg'
                    fontWeight='medium'
                    id={getBemId('cardSelected', 'paymentType', 'mobile')}
                  >
                    {paymentType()}
                  </Text>
                  <Row
                    alignItems='center'
                    maxWidth='max-content'
                  >
                    <Text
                      customColor={getGlobalStyle('--color-icons-gray')}
                      fontSize='md'
                      id={getBemId(
                        'cardSelected',
                        'lastNumberOfCards',
                        'mobile'
                      )}
                      lineHeight='14px'
                    >
                      {`****${getLastFourNumbersOfCard(
                        selectedCard?.cardNumber
                      )}`}
                    </Text>
                  </Row>
                </Column>
              </Row>
              <Row maxWidth='max-content'>
                <Text
                  fontSize='lg'
                  fontWeight='semibold'
                >
                  {selectedCard?.valueUsed}
                </Text>
              </Row>
            </Row>
            <Row
              backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
              borderRadius={getGlobalStyle('--border-radius-2sm')}
              hidden
              justifyContent='between'
              padding='8px 16px'
            >
              <Text
                fontSize='md'
                fontWeight='medium'
              >
                {CARD_SELECTED_TEXTS.SAVE_CARD}
              </Text>
              <ToggleSwitch
                customClass={styles['toggle-switch']}
                onClick={handleToggleSelectCheck}
                value={isSelectCheck}
              />
            </Row>
          </Column>
        </Row>
      </SmallScreen>
    </>
  )
}
