import {
  Backbone,
  Column,
  Container,
  Divider,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const SummaryBackbone = () => {
  return (
    <Container
      border={`0.3px solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius={getGlobalStyle('--border-radius-md')}
      customHeight='max-content'
      gap={12}
      maxHeight='543px'
      padding='16px'
    >
      <Column
        alignItems='center'
        gap={16}
      >
        <Row gap={20}>
          <Row maxWidth='90px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={14}
            />
          </Row>
          <Row maxWidth='90px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={14}
            />
          </Row>
        </Row>
        <Column gap={16}>
          {Array.from(Array(9).keys()).map((item) => {
            const dinamicWith = Math.random() * (160 - 60) + 60

            return (
              <Row
                justifyContent='between'
                key={item}
              >
                <Row maxWidth={dinamicWith.toString() + 'px'}>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={14}
                  />
                </Row>
                <Row maxWidth='70px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={14}
                  />
                </Row>
              </Row>
            )
          })}
        </Column>
        <Divider
          thickness={0}
          verticalMargin={0}
        />
        <Column gap={16}>
          {Array.from(Array(3).keys()).map((item) => {
            const dinamicWith = Math.random() * (160 - 60) + 60

            return (
              <Row
                justifyContent='between'
                key={item}
              >
                <Row maxWidth={dinamicWith.toString() + 'px'}>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={14}
                  />
                </Row>
                <Row maxWidth='70px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={14}
                  />
                </Row>
              </Row>
            )
          })}
        </Column>
        <Row maxWidth='316px'>
          <Backbone
            borderRadius={getGlobalStyle('--border-radius-md')}
            height={46}
          />
        </Row>
        <Row
          gap={10}
          justifyContent='between'
          maxWidth='316px'
        >
          <Row maxWidth='230px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={28}
            />
          </Row>
          <Row maxWidth='60px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={28}
            />
          </Row>
        </Row>
      </Column>
    </Container>
  )
}
