import { Column, Button } from '@smu-chile/pkg-unimarc-components'
import {
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface IModalCTA {
  onClose: () => void
}

export const ModalCTA = ({ onClose }: IModalCTA) => {
  return (
    <Column gap={16}>
      <Button
        borderRadius={getGlobalStyle('--border-radius-xl')}
        fontWeight='500'
        fullWidth
        id={getBemId('modalAlertMembership', 'primaryButton', 'desktop')}
        label='Revisar'
        onClick={onClose}
        status='initial'
        type='primary-gray'
      />
    </Column>
  )
}
