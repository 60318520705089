import React from 'react'
import { useRouter } from 'next/router'
import {
  Column,
  Container,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { ErrorReloadPageButton } from './ErrorReloadPageButton'
import { Error500Screen } from './Error500Screen'
import { ErrorImage } from './ErrorImage'

export type ErrorType = '400' | '404' | '500'

interface ErrorMessage {
  title: string
  subTitle: string
}

interface ErrorData {
  mobileBody?: string
  mobilePadding?: string
  errorTitle: string | JSX.Element
  errorSubTitle: string | JSX.Element
}

export interface ErrorPageV2Props {
  bodyBoxShadow?: string
  code?: string
  errorType?: string
  titleMaxWidth?: string
  imgUrl?: string
}

// define error messages in diccionary
const errorMessages: Partial<Record<ErrorType, ErrorMessage>> = {
  '404': {
    title: '¡EsPERA!',
    subTitle: ' Volvamos a intentar llegar a tu pago.'
  }
}

// create error object to reuseability
const errorData = (isMobile): ErrorData => {
  const mobileStyles = isMobile
    ? { mobileBody: '85dvh', mobilePadding: '12px 16px' }
    : {}
  const { title, subTitle } = errorMessages['404']

  return {
    ...mobileStyles,
    errorTitle: title,
    errorSubTitle: subTitle
  }
}

export const ErrorPageV2 = ({
  bodyBoxShadow = '',
  code = '',
  errorType = '404',
  titleMaxWidth,
  imgUrl = ''
}: ErrorPageV2Props): React.ReactElement => {
  // get error type from query
  const { query } = useRouter()
  const errorTypeQuery = query.errorType as ErrorType
  // get viewport width
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  // define variables to DRY
  const {
    mobileBody: bodyHeight,
    mobilePadding: bodyPadding,
    errorSubTitle: errorSubtitle,
    errorTitle
  } = errorData(isMobile)

  if (errorType === '500') {
    return (
      <Error500Screen
        imageUrl={imgUrl}
        isMobile={isMobile}
      />
    )
  }

  return (
    <Column
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-base-white')}
      borderRadius={getGlobalStyle('--border-radius-md')}
      boxShadow={bodyBoxShadow}
      height={bodyHeight}
      justifyContent='between'
      maxWidth={titleMaxWidth}
      minHeight={bodyHeight}
      padding={bodyPadding}
    >
      <Column alignItems='center'>
        {/* desktop spacers */}
        <Spacer.Horizontal customSize={!isMobile ? 65 : 40} />
        {/* desktop/mobile image */}
        <ErrorImage
          errorType={errorType}
          imageUrl={imgUrl}
          imgHeight='200px'
          imgWidth='200px'
          isMobile={isMobile}
        />
        <Spacer.Horizontal customSize={isMobile ? 24 : 16} />
        <Text
          fontSize={isMobile ? 'xl' : '2xl'}
          fontWeight='semibold'
          textAlign='center'
        >
          {(errorTitle as string).split('<br />').map((line, index) => {
            return (
              <React.Fragment key={index}>
                {line}
                {index < (errorTitle as string).split('<br />').length - 1 && (
                  <br />
                )}
              </React.Fragment>
            )
          })}
        </Text>
        <Spacer.Horizontal size={12} />
        <Container
          justifyContent='center'
          maxWidth='100%'
          padding='0 12px'
        >
          <Text
            fontSize='base'
            textAlign='center'
          >
            {errorSubtitle}
          </Text>
        </Container>
        <Spacer.Horizontal size={12} />
        <Container
          justifyContent='center'
          maxWidth='100%'
          padding='0 12px'
        >
          <Text
            customColor={getGlobalStyle('--color-neutral-gray-silver')}
            fontSize='md'
            textAlign='center'
          >
            Error: {code || errorTypeQuery || errorType}
          </Text>
        </Container>
        <Spacer.Horizontal customSize={66} />
      </Column>
      <ErrorReloadPageButton />
    </Column>
  )
}
