import { CSSProperties, useEffect } from 'react'
import { useRouter } from 'next/router'

import { Column, Container, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { Footer, MetaHead } from 'components'
import { LayoutHeader } from './components/layoutHeader'
import { cleanUrlParamsLogin, useOnScreen } from '@smu-chile/pkg-unimarc-hooks'
import { LAYOUT_WIDTH } from 'shared/constants'
import { useLayoutContext } from './context'

export interface LayoutProps {
  children: React.ReactNode
  backgroundColor?: CSSProperties['backgroundColor']
}

export const Layout = ({
  backgroundColor,
  children
}: LayoutProps): React.ReactElement => {
  const router = useRouter()
  const { elementRef } = useOnScreen({})
  const { showHeaderAndFooter } = useLayoutContext() || {}

  const background = backgroundColor ?? getGlobalStyle('--color-base-white')
  const getPropsOfChildren = (children as React.ReactElement)?.props?.children
    ?.props
  const getMaxWidth = getPropsOfChildren?.isMoble ? '100%' : LAYOUT_WIDTH

  useEffect(() => {
    cleanUrlParamsLogin({
      router,
      ignoreItems: ['q', 'page']
    })
  }, [])

  return (
    <Container
      backgroundColor={background}
      customHeight='100vh'
      isWrap
      justifyContent='center'
    >
      <Column alignItems='center'>
        <MetaHead titleName='Checkout' />
        {showHeaderAndFooter && <LayoutHeader />}
        {/* Body of component */}
        <Column maxWidth={getMaxWidth}>
          <Row>
            <Column>{children}</Column>
            <div ref={elementRef} />
          </Row>
        </Column>
      </Column>
      {/* footer */}
      {showHeaderAndFooter && <Footer />}
    </Container>
  )
}
