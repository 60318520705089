import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ErrorType } from '..'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { ErrorImage } from '../ErrorImage'

interface Error500ScreenProps {
  errorType?: ErrorType
  imageUrl: string
  isMobile: boolean
}

export const Error500Screen = ({
  imageUrl,
  isMobile,
  errorType = '500'
}: Error500ScreenProps): React.ReactElement => {
  const customSizes = {
    customHeight: '100%',
    minHeight: '100%'
  }
  return (
    <>
      <BigScreen>
        <Row
          customHeight='725px'
          minHeight='725px'
          padding='38px 0'
          tagName='section'
        >
          {/* Image */}
          <Column width='30%'>
            <ErrorImage
              errorType={errorType}
              imageUrl={imageUrl}
            />
          </Column>
          {/* Text */}
          <Column
            {...customSizes}
            width='70%'
          >
            <Row
              customHeight='100%'
              minHeight='100%'
            >
              {/* texts */}
              <Column
                {...customSizes}
                justifyContent='between'
              >
                {/* first block */}
                <Row>
                  <Column alignItems='end'>
                    <Text
                      color='primary'
                      fontSize='12xl'
                      fontWeight='bold'
                    >
                      ¿Jugamos al escondite?
                    </Text>
                    <Spacer.Vertical size={12} />
                    <Text
                      color='primary'
                      fontSize='11xl'
                    >
                      1, 2, 3...
                    </Text>
                    <Spacer.Vertical size={12} />
                    <Text
                      color='primary'
                      fontSize='11xl'
                    >
                      Pronto nos encontrarás
                    </Text>
                  </Column>
                </Row>
                {/* second block */}
                <Row>
                  <Column>
                    <Row justifyContent='end'>
                      <Column alignItems='end'>
                        <Text
                          color='primary'
                          fontSize='3xl'
                        >
                          En instantes volvemos
                        </Text>
                        <Text
                          color='primary'
                          fontSize='3xl'
                        >
                          para que puedas
                        </Text>
                        <Text
                          color='primary'
                          fontSize='3xl'
                        >
                          continuar con tu compra
                        </Text>
                      </Column>
                    </Row>
                    <Spacer.Horizontal size={32} />
                    {/* logo unimarc */}
                    <Row justifyContent='end'>
                      <Icon
                        clickable='pointer'
                        customSize={278}
                        name='LogoHeader'
                      />
                    </Row>
                    <Spacer.Horizontal size={32} />
                    {/* error code */}
                    <Row justifyContent='end'>
                      <Text
                        customColor={getGlobalStyle(
                          '--color-neutral-gray-input'
                        )}
                        fontSize='xs'
                        textAlign='center'
                      >
                        Código: {errorType}
                      </Text>
                    </Row>
                  </Column>
                </Row>
              </Column>
              {/* spacer */}
              <Column
                minWidth='96px'
                width='auto'
              >
                <Spacer.Horizontal size={96} />
              </Column>
            </Row>
          </Column>
        </Row>
      </BigScreen>

      <SmallScreen>
        <Container tagName='section'>
          <Row>
            <Column
              tagName='article'
              width='100%'
            >
              <Spacer.Horizontal size={64} />
              {/* Texts */}
              <Row {...customSizes}>
                {/* texts */}
                <Column
                  {...customSizes}
                  justifyContent='between'
                >
                  {/* first block */}
                  <Row>
                    <Column alignItems='center'>
                      <Text
                        color='primary'
                        fontSize='5xl'
                        fontWeight='semibold'
                      >
                        ¿Jugamos
                      </Text>
                      <Text
                        color='primary'
                        fontSize='5xl'
                        fontWeight='semibold'
                      >
                        al escondite?
                      </Text>
                      <Spacer.Vertical size={12} />
                      <Text
                        color='primary'
                        fontSize='2xl'
                        fontWeight='semibold'
                      >
                        1, 2, 3...
                      </Text>
                      <Spacer.Vertical size={12} />
                      <Text
                        color='primary'
                        fontSize='2xl'
                        fontWeight='semibold'
                      >
                        Pronto nos encontrarás
                      </Text>
                    </Column>
                  </Row>
                  {/* second block */}
                  <Row>
                    <Column>
                      <Row justifyContent='center'>
                        <Column alignItems='center'>
                          <Spacer.Horizontal size={96} />
                          <Text
                            color='primary'
                            fontSize='3xl'
                          >
                            En instantes volvemos
                          </Text>
                          <Text
                            color='primary'
                            fontSize='3xl'
                          >
                            para que puedas
                          </Text>
                          <Text
                            color='primary'
                            fontSize='3xl'
                          >
                            continuar con tu compra
                          </Text>
                        </Column>
                      </Row>
                      <Spacer.Horizontal size={32} />
                      {/* logo unimarc */}
                      <Row justifyContent='center'>
                        <Icon
                          clickable='pointer'
                          customSize={278}
                          name='LogoHeader'
                        />
                      </Row>
                      <Spacer.Horizontal size={32} />
                      {/* error code */}
                      <Row justifyContent='center'>
                        <Text
                          customColor={getGlobalStyle(
                            '--color-neutral-gray-input'
                          )}
                          fontSize='xs'
                          textAlign='center'
                        >
                          Código: {errorType}
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                </Column>
              </Row>
              <Spacer.Horizontal size={24} />
              {/* Image */}
              <Row
                customHeight='200px'
                justifyContent='center'
                overflow='hidden'
              >
                <ErrorImage
                  errorType={errorType}
                  imageUrl={imageUrl}
                  imgHeight='380px'
                  imgWidth='100%'
                  isMobile={isMobile}
                />
              </Row>
            </Column>
          </Row>
        </Container>
      </SmallScreen>
    </>
  )
}
