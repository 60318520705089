import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  DesktopModalWrapper,
  MobileModalWrapper,
  RichText
} from '@smu-chile/pkg-unimarc-components'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import { CONTENTFUL_IDS } from 'shared/constants'
import { IContentfulRichText } from '..'
import styles from './styles.module.css'

export interface IModalTermsAndConditionsProps {
  handleToggle: () => void
}

export const ModalTermsAndConditions = ({
  handleToggle
}: IModalTermsAndConditionsProps) => {
  const { data: termsData } = useContentful({
    options: {
      'sys.id': CONTENTFUL_IDS.TERMS_AND_CONDITIONS
    },
    reactQuery: {
      queryKey: 'terms-and-conditions'
    }
  })

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={getBemId('modal', 'terms-and-conditions')}
          bodyChildrenProps={{
            padding: '16px'
          }}
          bodyCustomClass={styles['container-rich-text']}
          footerChildrenProps={{
            default: true,
            buttonDefaultProps: {
              label: 'Entendido',
              fontWeight: getGlobalStyle('--font-weight-medium'),
              onClick: () => {
                handleToggle()
              }
            }
          }}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerTitle='Términos y condiciones'
          headerTitleProps={{
            height: '36px'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            isOpen: true,
            toggleOutside: handleToggle,
            toggle: handleToggle,
            maxWidth: '516px',
            maxHeight: '540px'
          }}
        >
          <RichText
            richTextData={[
              {
                ...(termsData as IContentfulRichText)?.items[0]?.fields?.value
                  .content
              }
            ]}
          />
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId={getBemId('modal', 'privacy-policy')}
          body={
            <Column>
              <RichText
                isMobile={true}
                richTextData={[
                  {
                    ...(termsData as IContentfulRichText)?.items[0]?.fields
                      ?.value.content
                  }
                ]}
              />
            </Column>
          }
          bodyContainerProps={{
            maxHeight: 'calc(100vh - 220px)'
          }}
          buttonConfigsProps={{
            label: 'Entendido',
            height: '40px',
            onClick: handleToggle
          }}
          dragProps={{
            draggButton: false
          }}
          footerContainerProps={{
            padding: '12px 16px'
          }}
          hasFooter={true}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          headerTitle='Términos y condiciones'
          hideHeaderDivider={true}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
