import { useEffect, useRef, useState } from 'react'
import { Icon, ModalOTPUnipay } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  patchPayment,
  postTransaction,
  postUnipayValidateSecurityCode,
  sleep,
  trigger,
  useCheckoutV2,
  useNewCartUnimarc
} from '@smu-chile/pkg-unimarc-hooks'
import { INTERNAL_SERVER_ERROR } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { useCheckoutConfig } from 'shared/hooks'
import {
  CODE_UNIPAY_WITH_INSTALLMENTS,
  CODE_UNIPAY_WITHOUT_INSTALLMENTS,
  ERROR_MESSAGE_UNIPAY_4,
  TOAST_MESSAGES,
  TOAST_MESSAGES_MODAL_UNIPAY_OTP,
  UNIPAY_EXCEPTIONS
} from 'shared/constants'
import {
  MODAL_OTP_UNIPAY_IDS,
  MODAL_OTP_UNIPAY_WRAPPER_CONFIGS
} from './constants'
import { addPaymentInfoEvent } from '../PayBtn/helpers'
import { IOtpData } from '../PaymentMethods'
import { ModalUnipayTotal } from '../PaymentMethods/ModalUnipay/ModalUnipayTotal'
import { useLayoutContext } from 'components/Layout/context'

interface IModalOTPUnipayWrapperProps {
  data: IOtpData
  handleToggle: () => void
  handleGoBack: () => void
  handleResentCodeUnipay: ({ rut }: { rut: string }) => void
}

export const ModalOTPUnipayWrapper = ({
  data,
  handleToggle,
  handleGoBack,
  handleResentCodeUnipay
}: IModalOTPUnipayWrapperProps) => {
  const [isEnablePayment, setIsEnablePayment] = useState(false)
  const [isConfirmOtpCode, setisConfirmOtpCode] = useState(false)
  const [isLoadingPaymentButton, setIsloadingPaymentButton] = useState(false)
  const [hasCountDown, setHasCountDown] = useState(false)
  const [countDownTimer, setCountDownTimer] = useState('00:00')
  const [otpValue, setOtpValue] = useState('')
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const { data: checkout, refetch: refetchCheckout } = useCheckoutV2()
  const { data: cart } = useNewCartUnimarc()
  const { checkoutConfig } = useCheckoutConfig()
  const { setShowHeaderAndFooter } = useLayoutContext()

  const isLoading =
    data?.isLoading || isConfirmOtpCode || isLoadingPaymentButton

  const findUniPayValues = checkout?.paymentInfo?.availablePaymentMethods?.find(
    (paymentMethod) => {
      return paymentMethod.id === CODE_UNIPAY_WITH_INSTALLMENTS
    }
  )
  const totalValue = findUniPayValues?.price || checkout?.value

  const handleCleanCodeForm = async () => {
    const inputs = fieldsetRef.current?.querySelectorAll('input')

    for (let i = 0; i < inputs.length; i++) {
      await sleep(5)
      inputs[i].focus()
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      ).set
      nativeInputValueSetter.call(inputs[i], '')

      const inputEvent = new Event('input', { bubbles: true })
      inputs[i].dispatchEvent(inputEvent)
    }
    inputs[0].focus()
  }

  const handleResendCode = async () => {
    handleResentCodeUnipay({ rut: data?.rut })
    handleCleanCodeForm()
    startCountDown()
    trigger({
      eventType: 'checkoutToast',
      data: {
        portalRootId: 'portal-otp',
        type: 'info',
        show: true,
        toastMessage: TOAST_MESSAGES_MODAL_UNIPAY_OTP.info
      }
    })
  }

  const handleTransaction = async () => {
    const prevPaymentData = checkout?.paymentInfo?.selectedPayments?.[0]

    try {
      setIsloadingPaymentButton(true)
      setIsEnablePayment(false)
      setShowHeaderAndFooter(false)
      trigger({
        eventType: 'isLoadingPaymentButton',
        data: {
          isLoading: true
        }
      })

      const hasQuotas = data?.simulatePaymentData?.quotas > 0
      const unipayName = hasQuotas ? 'Unipay con cuotas' : 'Unipay sin cuotas'
      const paymentSystemId = hasQuotas
        ? CODE_UNIPAY_WITH_INSTALLMENTS
        : CODE_UNIPAY_WITHOUT_INSTALLMENTS

      const updatePayment = await patchPayment({
        data: [
          {
            id: paymentSystemId,
            name: unipayName,
            cardId: data?.rut,
            cardType: 'credit',
            valueUsed: totalValue
          }
        ]
      })
      const statusString = updatePayment?.status.toString()
      const isValidStatus = /^20[02]$/.test(statusString)

      if (!isValidStatus) {
        if (prevPaymentData?.id) {
          const findPaymentMethod =
            checkout?.paymentInfo?.availablePaymentMethods?.find(
              (paymentMethod) => {
                return paymentMethod.id === paymentMethod?.id
              }
            )
          await patchPayment({
            data: [
              {
                id: prevPaymentData?.id,
                name: findPaymentMethod?.name,
                cardId: prevPaymentData?.cardId,
                cardType: prevPaymentData?.cardType,
                valueUsed: prevPaymentData?.valueUsed
              }
            ]
          })
        } else {
          window?.location?.reload()
        }
        trigger({
          eventType: 'isLoadingPaymentButton',
          data: {
            isLoading: false
          }
        })
        setShowHeaderAndFooter(true)
        setIsloadingPaymentButton(false)
        setIsEnablePayment(true)
        handleToggle()
        return
      }

      const response = await postTransaction({
        value: updatePayment?.data?.value,
        selectedDeliveryWindows: [
          { ...checkout?.selectedDeliveryWindows?.[0] }
        ],
        additionalData: {
          otp: otpValue,
          card: data?.rut,
          total: data?.simulatePaymentData?.total,
          quotas: data?.simulatePaymentData?.quotas || 1,
          balance: data?.simulatePaymentData?.balance,
          increase: data?.simulatePaymentData?.increase,
          monthLack: parseInt(data?.simulatePaymentData?.monthLack) || 0,
          quotaValue: data?.simulatePaymentData?.quotaValue,
          cae: data?.simulatePaymentData?.cae
        }
      })

      if (response?.status === INTERNAL_SERVER_ERROR || response?.data?.code) {
        if (prevPaymentData?.id) {
          const findPaymentMethod =
            checkout?.paymentInfo?.availablePaymentMethods?.find(
              (paymentMethod) => {
                return paymentMethod.id === paymentMethod?.id
              }
            )
          await patchPayment({
            data: [
              {
                id: prevPaymentData?.id,
                name: findPaymentMethod?.name,
                cardId: prevPaymentData?.cardId,
                cardType: prevPaymentData?.cardType,
                valueUsed: prevPaymentData?.valueUsed
              }
            ]
          })
        } else {
          window?.location?.reload()
        }
        await refetchCheckout()
        trigger({
          eventType: 'isLoadingPaymentButton',
          data: {
            isLoading: false
          }
        })
        const deliveryWindowErrors = ['CHK-024', 'CHK-027']
        if (deliveryWindowErrors.includes(response?.data?.code)) {
          trigger({
            eventType: 'toggleErrorDeliveryWindow',
            data: {
              code: response?.data?.code,
              show: true
            }
          })
        } else {
          trigger({
            eventType: 'checkoutToast',
            data: {
              codeError: response?.data?.code || 'error',
              show: true,
              toastMessage: TOAST_MESSAGES.error_transaction
            }
          })
        }
        setShowHeaderAndFooter(true)
        setIsloadingPaymentButton(false)
        setIsEnablePayment(true)
        handleToggle()
        return
      }
      // trigger add_payment_info tagmanager event to tracking purchase KPI
      addPaymentInfoEvent({
        checkout,
        cart,
        orderformId: checkoutConfig?.orderformId,
        saleschannel: checkoutConfig?.saleschannel
      })
      await sleep(800)
      // prepare url redirect
      const orderId = response?.data?.orderId
      const url = `${process.env.NEXT_PUBLIC_ORDERPLACED}/?og=${orderId}`
      window.location.href = url
    } catch (error) {
      await refetchCheckout()
      trigger({
        eventType: 'isLoadingPaymentButton',
        data: {
          isLoading: false
        }
      })
      setShowHeaderAndFooter(true)
      setIsloadingPaymentButton(false)
      setIsEnablePayment(true)
      handleToggle()
      // eslint-disable-next-line no-console
      console.error({ error })
    }
  }

  const handleChangeCodeInput = (value: string) => {
    if (value?.length === 6) {
      setOtpValue(value)
      handleVerificationCode(value)
    } else {
      setIsEnablePayment(false)
    }
  }

  const handleVerificationCode = async (code: string) => {
    setisConfirmOtpCode(true)
    const responseValidationCode = await postUnipayValidateSecurityCode({
      data: {
        token: data?.token,
        rut: data?.rut,
        otp: code
      }
    })

    if (responseValidationCode.status === 204) {
      trigger({
        eventType: 'checkoutToast',
        data: {
          portalRootId: 'portal-otp',
          success: true,
          show: true,
          toastMessage: TOAST_MESSAGES_MODAL_UNIPAY_OTP.success
        }
      })
      setIsEnablePayment(true)
    } else {
      const errorCode = responseValidationCode?.code || ''
      const errorMessage =
        errorCode?.length > 0
          ? UNIPAY_EXCEPTIONS.find((exception) => {
              return exception.code_errors.includes(
                responseValidationCode?.code
              )
            })?.message
          : ''
      trigger({
        eventType: 'checkoutToast',
        data: {
          portalRootId: 'portal-otp',
          codeError: errorCode,
          show: true,
          toastMessage: errorMessage || ERROR_MESSAGE_UNIPAY_4
        }
      })
    }
    setisConfirmOtpCode(false)
  }

  const startCountDown = () => {
    setCountDownTimer('00:30')
    setHasCountDown(true)
  }

  useEffect(() => {
    if (!hasCountDown) {
      startCountDown()
    }
  }, [])

  useEffect(() => {
    // count down timer for resend code
    const interval = setInterval(() => {
      const [minutes, seconds] = countDownTimer.split(':').map(Number)
      if (minutes === 0 && seconds === 0) {
        clearInterval(interval)
        setHasCountDown(false)
      } else {
        const newSeconds = seconds - 1
        setCountDownTimer(
          `${minutes}:${newSeconds.toString().padStart(2, '0')}`
        )
      }
    }, 1000)

    return () => {
      return clearInterval(interval)
    }
  }, [countDownTimer, hasCountDown])

  return (
    <ModalOTPUnipay
      countDownTimer={
        hasCountDown ? `Pide uno nuevo en ${countDownTimer}` : '00:00'
      }
      desktopModalWrapperProps={{
        headerCloseIcon: {
          customSize: 13
        },
        bodyChildrenProps: {
          overflow: 'auto',
          maxHeight: 'initial',
          padding: '20px'
        },
        blockId: MODAL_OTP_UNIPAY_IDS.DESKTOP_MODAL,
        modalConfigsProps: {
          maxHeight: MODAL_OTP_UNIPAY_WRAPPER_CONFIGS.HEIGHT_DESKTOP,
          minHeight: MODAL_OTP_UNIPAY_WRAPPER_CONFIGS.HEIGHT_DESKTOP
        },
        footerChildrenProps: {
          default: false,
          boxShadow: 'initial',
          borderEdge: 'all',
          borderRadius: '0',
          buttonDefaultProps: {
            label: 'Pagar',
            status: isEnablePayment ? 'initial' : 'disabled',
            background: isEnablePayment
              ? getGlobalStyle('--color-primary-red2')
              : undefined,
            topExtraChildren: <ModalUnipayTotal totalValue={totalValue} />
          }
        }
      }}
      fieldsetRef={fieldsetRef}
      handleChangeCodeInput={handleChangeCodeInput}
      handleGoBack={handleGoBack}
      handleResendCode={handleResendCode}
      handleToggle={handleToggle}
      handleValidateOTP={handleTransaction}
      hasCountDown={hasCountDown}
      isConfirming={isConfirmOtpCode}
      isLoading={isLoading}
      mobileModalWrapperProps={{
        headerCloseIconProps: {
          customSize: 13
        },
        headerLeftElement: (
          <Icon
            color={getGlobalStyle('--color-base-black')}
            customId={getBemId(`${MODAL_OTP_UNIPAY_IDS.MOBILE_MODAL}`, 'icon')}
            customSize={20}
            name='ArrowRightNavigate2'
            onClick={handleToggle}
            transform='rotate(180deg)'
          />
        ),
        footerContainerProps: {
          boxShadow: 'initial',
          padding: '16px'
        },
        buttonConfigsProps: {
          status: isEnablePayment ? 'initial' : 'disabled',
          background: isEnablePayment
            ? getGlobalStyle('--color-primary-red2')
            : undefined,
          onClick: handleTransaction,
          isLoading: isLoading || isConfirmOtpCode,
          topExtraChildren: <ModalUnipayTotal totalValue={totalValue} />,
          label: 'Pagar'
        }
      }}
      phone={data?.phone}
    />
  )
}
