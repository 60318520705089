import {
  DesktopModalWrapper,
  Container,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  getBemId,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ModalBody } from './ModalBody'
import { ModalCTA } from './ModalCTA'

interface ModalAlertMembershipProps {
  onClose: () => void
}

export const ModalAlertMembership = ({
  onClose
}: ModalAlertMembershipProps): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={getBemId('modalAlertMembership', 'container')}
          bodyChildrenProps={{
            minHeight: '58%',
            padding: '0 16px'
          }}
          borderEdge='none'
          footerChildrenProps={{
            boxShadow: '0',
            default: false,
            padding: '16px',
            children: <ModalCTA onClose={onClose} />
          }}
          headerCloseIcon={{
            name: 'CloseThin',
            customSize: 20,
            default: true
          }}
          modalConfigsProps={{
            delayOnBlockWindow: true,
            isWindowBlocked: true,
            maxHeight: '435px',
            minHeight: '435px',
            isOpen: true,
            toggle: onClose,
            toggleOutside: onClose
          }}
          styleProps={{
            padding: '0',
            customHeightModalContent: '100%'
          }}
        >
          <Container
            customHeight='100%'
            tagName='section'
          >
            <ModalBody />
          </Container>
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId={getBemId('modalAlertMembership', 'container')}
          body={
            <Container
              alignItems='center'
              customHeight='100%'
              tagName='section'
            >
              <ModalBody isMobile={true} />
            </Container>
          }
          customFooter={<ModalCTA onClose={onClose} />}
          dragProps={{
            draggButton: false
          }}
          hasShadow={false}
          headerCloseIconProps={{
            name: 'CloseThin',
            customSize: 20
          }}
          hideHeaderDivider={false}
          iconSize={20}
          isEnableButton={false}
          modalConfigsProps={{
            delayOnBlockWindow: true,
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: onClose,
            toggleOutside: onClose
          }}
          onClose={onClose}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
