import { useState } from 'react'
import { useEvents } from '@smu-chile/pkg-unimarc-hooks'
import {
  Container,
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ModalCTA } from './ModalCTA'
import { ModalBody } from './ModalBody'
import { useDataLayers } from 'shared/hooks'

export const ModalErrorDeliveryWindow = () => {
  const [showErrorDeliveryWindow, setShowErrorDeliveryWindow] = useState(false)
  const [codeError, setCodeError] = useState('CHK-024')

  const { pushCheckoutEvent } = useDataLayers()

  useEvents({
    eventType: 'toggleErrorDeliveryWindow',
    callBack: ({ detail: { show, code } }) => {
      pushCheckoutEvent({ endpoint: 'transaction', error: code })
      setShowErrorDeliveryWindow(show)
      setCodeError(code)
    }
  })

  const onClose = () => {
    window.location.href = process.env.NEXT_PUBLIC_CARTURL + '/MyShipments'
  }

  if (!showErrorDeliveryWindow) return null

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={getBemId('modalErrorDeliveryWindow', 'container')}
          bodyChildrenProps={{
            flexGrow: 1,
            overflow: 'hidden',
            padding: '0'
          }}
          borderEdge='none'
          footerChildrenProps={{
            boxShadow: '0',
            default: false,
            padding: '12px 18px',
            children: <ModalCTA onClose={onClose} />
          }}
          headerCloseIcon={{ default: false }}
          modalConfigsProps={{
            delayOnBlockWindow: true,
            isWindowBlocked: true,
            maxHeight: '440px',
            minHeight: '440px',
            isOpen: true,
            toggle: onClose
          }}
          styleProps={{
            padding: '0',
            customHeightModalContent: '100%'
          }}
        >
          <Container
            customHeight='100%'
            tagName='section'
          >
            <ModalBody codeError={codeError} />
          </Container>
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId={getBemId('modalErrorDeliveryWindow', 'container')}
          body={
            <Container
              alignItems='center'
              customHeight='100%'
              tagName='section'
            >
              <ModalBody
                codeError={codeError}
                isMobile={true}
              />
            </Container>
          }
          customFooter={<ModalCTA onClose={onClose} />}
          dragProps={{
            draggButton: false
          }}
          hasShadow={false}
          hideHeaderDivider={false}
          iconSize={20}
          isEnableButton={false}
          isHiddenHeaderCloseButton={true}
          modalConfigsProps={{
            delayOnBlockWindow: true,
            openAnimation: true,
            hiddenX: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: onClose,
            maxHeightFullScreen: '550px',
            minHeightFullScreen: '550px'
          }}
          onClose={onClose}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
