import {
  Column,
  Icon,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { getLastFourNumbersOfCard } from 'shared/helpers'
import { IGiftCard } from '..'

interface GifcardSelectedProps {
  gifcardData: IGiftCard
  totalAmount: string
  handleToggleGiftCardModal: () => void
}

export const GifcardSelected = ({
  gifcardData,
  totalAmount,
  handleToggleGiftCardModal
}: GifcardSelectedProps): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <Column
          border={`1px solid ${getGlobalStyle('--color-primary-light-red')}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          gap={16}
          id={getBemId('giftcard-selected', 'container')}
          padding='16px'
        >
          <Row justifyContent='between'>
            <Row maxWidth='max-content'>
              <Text
                fontSize='base'
                fontWeight='medium'
                id={getBemId('giftcard-title', 'container')}
              >
                Mi Giftcard
              </Text>
            </Row>
            <Text
              clickable='pointer'
              customColor={getGlobalStyle('--color-primary-light-red')}
              fontWeight='medium'
              id={getBemId('giftcard', 'cambiar', 'buttonDesktop')}
              lineHeight='17px'
              onClick={handleToggleGiftCardModal}
            >
              Cambiar
            </Text>
          </Row>
          <Row
            alignItems='center'
            customHeight='42px'
            justifyContent='between'
          >
            <Row
              gap={12}
              id={getBemId('giftcard-info', 'container')}
              maxWidth='max-content'
            >
              <Picture
                hasURL
                height='42px'
                id={getBemId('giftcard', 'image')}
                src='/images/giftcard.png'
                width='42px'
              />
              <Column
                gap={4}
                id={getBemId('giftcard-info', 'detail')}
                maxWidth='max-content'
              >
                <Text
                  fontSize='base'
                  fontWeight='semibold'
                  id={getBemId('giftcard', 'number')}
                >
                  ****{getLastFourNumbersOfCard(gifcardData?.cardNumber)}
                </Text>
                <Text
                  color='grayNeutral'
                  fontSize='md'
                  id={getBemId('giftcard', 'balance')}
                >
                  Saldo: {gifcardData?.balance}
                </Text>
              </Column>
            </Row>
            <Row maxWidth='max-content'>
              <Text
                fontSize='base'
                fontWeight='medium'
                id={getBemId('giftcard', 'buy', 'amount')}
              >
                {totalAmount}
              </Text>
            </Row>
          </Row>
          {!gifcardData?.hasEnoughAmount && (
            <Row
              alignItems='center'
              gap={5}
              id={getBemId('giftcard-info', 'container')}
            >
              <Icon
                color={getGlobalStyle('--color-feedback-info')}
                customSize={24}
                name='InfoFillThin'
              />
              <Text
                fontSize='md'
                id={getBemId('giftcard-info', 'message')}
              >
                El saldo no es suficiente, por favor escoge otra Giftcard o
                medio de pago.
              </Text>
            </Row>
          )}
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column
          border={`1px solid ${getGlobalStyle('--color-primary-light-red')}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          gap={12}
          id={getBemId('giftcard-selected', 'container')}
          padding='16px'
        >
          <Column gap={8}>
            <Row justifyContent='between'>
              <Row maxWidth='max-content'>
                <Text
                  fontSize='md'
                  fontWeight='medium'
                >
                  Mi Giftcard
                </Text>
              </Row>
              <Text
                clickable='pointer'
                customColor={getGlobalStyle('--color-primary-light-red')}
                fontWeight='medium'
                id={getBemId('giftcard', 'cambiar', 'buttonMobile')}
                lineHeight='17px'
                onClick={handleToggleGiftCardModal}
              >
                Cambiar
              </Text>
            </Row>
            <Row
              alignItems='center'
              customHeight='37px'
              id={getBemId('giftcard-info', 'container')}
              justifyContent='between'
            >
              <Row
                alignItems='center'
                gap={12}
                maxWidth='max-content'
              >
                <Picture
                  hasURL
                  height='30px'
                  id={getBemId('giftcard', 'image')}
                  src='/images/giftcard.png'
                  width='30px'
                />
                <Column
                  gap={4}
                  maxWidth='max-content'
                >
                  <Text
                    fontSize='md'
                    fontWeight='medium'
                  >
                    ****
                    {getLastFourNumbersOfCard(gifcardData?.cardNumber)}
                  </Text>
                  <Text
                    color='grayNeutral'
                    fontSize='sm'
                  >
                    Saldo: {gifcardData?.balance}
                  </Text>
                </Column>
              </Row>
              <Row maxWidth='max-content'>
                <Text
                  fontSize='base'
                  fontWeight='medium'
                >
                  {totalAmount}
                </Text>
              </Row>
            </Row>
          </Column>
          {!gifcardData?.hasEnoughAmount && (
            <Row
              alignItems='center'
              gap={5}
              id={getBemId('giftcard-info', 'container')}
              padding='2px 0'
            >
              <Row
                customHeight='20px'
                customWidth='20px'
                id={getBemId('giftcard-info', 'icon')}
                maxWidth='max-content'
              >
                <Icon
                  color={getGlobalStyle('--color-feedback-info')}
                  customSize={20}
                  name='InfoFillThin'
                />
              </Row>
              <Text
                fontSize='sm'
                id={getBemId('giftcard-info', 'message')}
              >
                El saldo no es suficiente, por favor escoge otra Giftcard o
                medio de pago.
              </Text>
            </Row>
          )}
        </Column>
      </SmallScreen>
    </>
  )
}
