import {
  isValidArrayWithData,
  normalizeString
} from '@smu-chile/pkg-unimarc-hooks'
import { PAYMENT_CARD_TYPES } from 'shared/constants'

/**
 * filter all cards that are not giftcard
 * @param {Object[]} dataCards - The dataset containing the Payment Cards.
 * @returns {Object[]} The list of filtered payment cards.
 */
export const filterCards = (dataCards) => {
  const listCards =
    isValidArrayWithData(dataCards) &&
    dataCards
      ?.filter((item) => {
        return (
          normalizeString(item.cardType) !==
          normalizeString(PAYMENT_CARD_TYPES.GIFTCARD)
        )
      })
      .reverse()

  return listCards
}
